import ValidService from "../../../service/ValidService";
let model = {};

// dữ liệu form
model.dataForm = {
  application: {
    "code": null,
    "name": null,
    "environments": [],
    "userIds": [],
    "defaultEnv": "Production"
  }

};
// validation form
model.validForm = {
  application: {
    code: [ValidService.required],
    name: [ValidService.required],
    defaultEnv: [ValidService.required],
    userIds:[],
    runtimeEnv: [ValidService.required]
  }
};

// dữ liệu mẫu các form khác
model.dataFormOther = {
  "environment": {
    "envType": "Production",
    "privateKey": null,
    "callBackUrl": null
  }
};

// validation các form khác
model.validFormOther = {
  environment: {
    envType: [ValidService.required],
    privateKey: [ValidService.required],
    callBackUrl: [ValidService.required, ValidService.checkLink],
  }
};

model.tableRules = {
  data: [],
  lengthMenu: [10, 20, 50, 100, 500],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 10,
  offset: 0,
  sort: "",
  defaultSort: ["account", "descending"],
  filters: "",
  recordSelected: [],
  dataSearch: {
    value: {
      name: "",
      defaultEnv: ""
    },
    operator: {
      name: ":regex_i:",
      defaultEnv: ":regex_i:"
    }
  }
};

export default model;
