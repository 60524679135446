const filters = {
  capitalize: function (value) {
    console.log('filter', value);

    if (!value) return '';
    value = value.toString();
    return value.toUpperCase();
  }
};

export default filters;