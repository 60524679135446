import Vue from 'vue';
import Router from 'vue-router';
import AuthService from '../service/AuthService';

const SurveyInformation = () => import('@/views/admin/Survey/SurveyInformation');
// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer');
const DefaultContainerClient = () => import('@/containers/DefaultContainerClient');

// Views - admin
const AdminDashboard = () => import('@/views/admin/Dashboard/Dashboard');

// const AdminUserList = () => import('@/views/admin/User/UserList');
// const AdminUserForm = () => import('@/views/admin/User/UserForm');

const AdminSurveyList = () => import('@/views/admin/Survey/SurveyList');
const AdminSurveyForm = () => import('@/views/admin/Survey/SurveyForm');

const AdminSurveyCharts = () => import('@/views/admin/Survey/SurveyCharts');
const AdminSurveyDetail = () => import('@/views/admin/Survey/SurveyDetail');

const AdminApplicationList = () => import('@/views/admin/Application/ApplicationList');
const AdminApplicationForm = () => import('@/views/admin/Application/ApplicationForm');
const AdminUserList = () => import('@/views/admin/User/UserList');
const AdminUserForm = () => import('@/views/admin/User/UserForm');


// Views - auth
const AuthPage404 = () => import('@/views/auth/Page404');
const AuthLogin = () => import('@/views/auth/Login');
const AuthRegister = () => import('@/views/auth/Register');
const AuthChangePassword = () => import('@/views/auth/ChangePassword');
const AuthForgotPassword = () => import('@/views/auth/ForgotPassword');
const AuthResetPassword = () => import('@/views/auth/ResetPassword');


// Views - client
const ClientHome = () => import('@/views/client/Home');

Vue.use(Router)
/* Các quyền người dùng: Admin - nhân viên Navifeed; Owner - Chủ trang trại; Engineer - Kỹ sư; Worker - Công nhân */
//ex roles: ["Admin", "Owner", "Engineer", "Worker"]

const router = new Router({
  base: window.MyConfig.pathDefault,
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [
    {
      path: '/',
      redirect: window.MyConfig.defaultRouter,
      // component: DefaultContainerClient
    },
    {
      path: '/admin',
      redirect: '/admin/home',
      component: DefaultContainer,
      children: [
        {
          path: 'home',
          name: 'Admin-Home',
          component: AdminDashboard,
          meta: {
            allowAccess: ['*'],
            page: 'admin'
          },
        },
        //Survey
        {
          path: 'Survey',
          name: 'Admin-Survey',
          redirect: '/admin/Survey/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'list',
              name: 'Admin-Survey-list',
              component: AdminSurveyList,
              meta: {
                allowAccess: ['Admin','User'],
                page: 'admin'
              },
            },
            {
              path: 'create',
              name: 'Admin-Survey-create',
              component: AdminSurveyForm,
              meta: {
                allowAccess: ['Admin','User'],
                page: 'admin'
              },
            },
            {
              path: 'update/:id',
              name: 'Admin-Survey-update',
              component: AdminSurveyForm,
              meta: {
                allowAccess: ['Admin','User'],
                page: 'admin'
              },
            },
            {
              path: 'detail/:id',
              name: 'Admin-Survey-detail',
              component: AdminSurveyDetail,
              meta: {
                allowAccess: ['Admin','User'],
                page: 'admin'
              },
            },
            {
              path: 'charts/:id',
              name: 'Admin-Survey-charts',
              component: AdminSurveyCharts,
              meta: {
                allowAccess: ['Admin','User'],
                page: 'admin'
              },

            },
            {
              path: 'information/:id',
              name: 'Admin-Survey-information',
              component: SurveyInformation,
              meta: {
                allowAccess: ['Admin'],
                page: 'my-form'
              },
            },
          ]
        },
        //User
        {
          path: 'User',
          name: 'Admin-User',
          redirect: '/admin/User/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'list',
              name: 'Admin-User-list',
              component: AdminUserList,
              meta: {
                allowAccess: ['AdminSystem'],
                page: 'admin'
              },
            },
            {
              path: 'create',
              name: 'Admin-User-create',
              component: AdminUserForm,
              meta: {
                allowAccess: ['AdminSystem'],
                page: 'admin'
              },
            },
            {
              path: 'update/:id',
              name: 'Admin-User-update',
              component: AdminUserForm,
              meta: {
                allowAccess: ['AdminSystem'],
                page: 'admin'
              },
            },
            {
              path: 'detail/:id',
              name: 'Admin-User-detail',
              component: AdminUserForm,
              meta: {
                allowAccess: ['AdminSystem'],
                page: 'admin'
              },
            },
          ]
        },
        //Application
        {
          path: 'Application',
          name: 'Admin-Application',
          redirect: '/admin/Application/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'list',
              name: 'Admin-Application-list',
              component: AdminApplicationList,
              meta: {
                allowAccess: ['Admin','AdminSystem'],
                page: 'admin'
              },
            },
            {
              path: 'create',
              name: 'Admin-Application-create',
              component: AdminApplicationForm,
              meta: {
                allowAccess: ['Admin','AdminSystem'],
                page: 'admin'
              },
            },
            {
              path: 'update/:id',
              name: 'Admin-Application-update',
              component: AdminApplicationForm,
              meta: {
                allowAccess: ['Admin','AdminSystem'],
                page: 'admin'
              },
            },
            {
              path: 'detail/:id',
              name: 'Admin-Application-detail',
              component: AdminApplicationForm,
              meta: {
                allowAccess: ['Admin','AdminSystem'],
                page: 'admin'
              },
            },
          ]
        }
      ]
    },
    {
      path: '/auth',
      redirect: '/auth/login',
      name: 'Auth',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: '404',
          name: 'Auth-Page404',
          component: AuthPage404,
          meta: {
            page: 'auth'
          },
        },
        {
          path: 'login',
          name: 'Auth-Login',
          component: AuthLogin,
          meta: {
            page: 'auth'
          },
        },
        {
          path: 'register',
          name: 'Auth-Register',
          component: AuthRegister,
          meta: {
            page: 'auth'
          },
        },
        {
          path: 'changepassword',
          name: 'Auth-ChangePassword',
          component: AuthChangePassword,
          meta: {
            page: 'auth'
          },
        },
        {
          path: 'forgotPassword',
          name: 'Auth-ForgotPassword',
          component: AuthForgotPassword,
          meta: {
            page: 'auth'
          },
        },
        {
          path: 'resetPassword',
          name: 'Auth-ResetPassword',
          component: AuthResetPassword,
          meta: {
            page: 'auth'
          },
        }
      ]
    }
  ]
})
export default router;

//check route
router.beforeEach(function (to, from, next) {
  if (from.name != to.name) {
    if (to.meta.page && to.meta.page == 'admin') {
      AuthService.me().then(
        res => {
          window.localStorage.setItem("currentUser", JSON.stringify(res.result));
          if (to.meta.allowAccess && to.meta.allowAccess.indexOf("*") == 0) {
            next();
          } else {
            if (res.result && res.result.roles && res.result.roles.length > 0) {
              var access = false;
              res.result.roles.forEach(item => {
                if (to.meta.allowAccess && to.meta.allowAccess.indexOf(item) != -1) {
                  access = true;
                }
              })
              if (access) {
                next();
              } else {
                toastr.warning("Không có quyền truy cập trang này")
              }
            }
          }
        }
      )
        .catch(error => {
          window.localStorage.removeItem("currentUser");
          next('/auth/login');
        });
    } else if (to.meta.page && to.meta.page != 'admin') {
      next();
    } else {
      next('/auth/404');
    }
  } else {
    if (!to.name) {
      next('/auth/404');
    }
    else {
      next();
    }
  }

})
