<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header">
        {{ aLang.c_list }} biểu mẫu
        <div class="card-header-actions"></div>
      </div>
      <div class="card-body">
        <div class="text-left">
          <b-collapse
            v-bind:visible="mData.tableRules.showFormSearch"
            id="myFormSearch"
          >
            <b-card>
              <el-form
                ref="myForm"
                label-position="top"
                @submit.native.prevent
                :model="mData.tableRules.dataSearch.value"
                :rules="mData.tableRules.dataSearch.valid"
              >
                <el-form-item class="text-center mb-0">
                  <el-button
                    type="info"
                    native-type="submit"
                    plain
                    @click="fn_tableSearch()"
                    >{{ aLang.c_search }}
                  </el-button>
                </el-form-item>
              </el-form>
            </b-card>
          </b-collapse>
        </div>
        <b-row>
          <b-col md="8" class="p-0">
            <b-col md="4"></b-col>
          </b-col>
          <b-col md="4">
            <div class="text-right mb-2">
              <el-button @click="fn_tableShowFormSearch()" type="info" plain
                >{{ aLang.c_showHideSearch }}
              </el-button>
              <el-button @click="fn_handle('create')" type="info" plain>{{
                aLang.c_create
              }}</el-button>
            </div>
          </b-col>
        </b-row>

        <el-table
          :data="mData.tableRules.data"
          border
          @selection-change="fn_tableSelectionChange"
          @sort-change="fn_tableSortChange"
          max-height="600"
          style="width: 100%"
          :default-sort="{
            prop: mData.tableRules.defaultSort[0],
            order: mData.tableRules.defaultSort[1],
          }"
        >
          <el-table-column
            type="selection"
            width="40"
            v-if="mData.tableRules.allowSelect"
          ></el-table-column>
          <el-table-column
            prop="name"
            :sortable="mData.tableRules.allowSorting"
            label="Tên biểu mẫu"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="description"
            :sortable="mData.tableRules.allowSorting"
            label="Mô tả"
            min-width="200"
          ></el-table-column>

          <el-table-column
            prop="expriedDate"
            :sortable="mData.tableRules.allowSorting"
            label="Ngày hết hạn"
            width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="createdTime"
            :sortable="mData.tableRules.allowSorting"
            label="Ngày tạo"
            width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            label="Kết quả khảo sát"
            align="center"
            width="300px"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="fn_handle('detail', scope.$index, scope.row)"
                v-b-popover.hover.left="`Kết quả khảo sát`"
              >
                <i class="fa-regular fa-eye"></i>
              </el-button>
              <el-button
                size="mini"
                @click="fn_handle('charts', scope.$index, scope.row)"
                v-b-popover.hover.left="`Biểu đồ`"
                type="warning"
                plain
              >
                <i class="fa-regular fa-chart-pie-simple"></i>
              </el-button>

              <el-button
                size="mini"
                @click="fn_handle('copy', scope.$index, scope.row)"
                v-b-popover.hover.left="'Sao chép'"
                type="success"
                plain
              >
                <i class="fa-regular fa-copy"></i>
              </el-button>
              <el-button
                size="mini"
                @click="fn_handle('update', scope.$index, scope.row)"
                v-b-popover.hover.left="aLang.c_update"
                type="primary"
                plain
              >
                <i class="fa-regular fa-pen"></i>
              </el-button>
              <el-button
                size="mini"
                @click="fn_handle('delete', scope.$index, scope.row)"
                v-b-popover.hover.left="aLang.c_delete"
                type="danger"
                plain
              >
                <i class="fa-regular fa-trash-can-xmark"></i>
              </el-button>
              <el-button
                size="mini"
                @click="fn_handle('getInfo', scope.$index, scope.row)"
                v-b-popover.hover.left="aLang.c_info"
                type="success"
                plain
              >
                <i class="fa-regular fa-circle-info"></i>
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <b-row class="mt-2" v-if="mData.tableRules.allowPaging">
          <div class="col-md-4">
            <div v-if="mData.tableRules.allowSelect">
              {{ aLang.c_select }}: {{ mData.tableRules.recordSelected.length }}
            </div>
          </div>
          <div class="col-md-8 text-right">
            <el-pagination
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
              :page-sizes="mData.tableRules.lengthMenu"
              :page-size="mData.tableRules.limit"
              :current-page="mData.tableRules.page"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="mData.tableRules.total"
              :pager-count="5"
            ></el-pagination>
          </div>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageService from "../../../service/LanguageService";
import MethodService from "../../../service/MethodService";
import AccountApi from "../../../moduleApi/AccountApi";
import AccountModel from "./SurveyModel";
import SurveyApi from "../../../moduleApi/SurveyApi";
export default {
  data() {
    return {
      aRole: MethodService.checkRole,
      aLang: LanguageService.lang,
      aElement: MethodService.element,
      mData: {
        moduleName: "Survey", //requied
        tableRules: MethodService.copyObject(AccountModel.tableRules),
        dataDefault: {
          listFindNongTrai: [],
        },
      },
    };
  },

  methods: {
    //get list table
    async fn_initTable() {
      let dataFilter = {
        filters: this.mData.tableRules.filters,
      };

      if (this.mData.tableRules.allowPaging) {
        dataFilter.limit = this.mData.tableRules.limit;
        dataFilter.offset = this.mData.tableRules.offset;
      }
      if (this.mData.tableRules.allowSorting) {
        dataFilter.sort = this.mData.tableRules.sort;
      }

      if (this.mData.tableRules.showUrl) {
        this.$router
          .replace({
            name: "Admin-" + this.mData.moduleName + "-list",
            query: {
              limit: this.mData.tableRules.limit,
              page: this.mData.tableRules.page,
              sort: this.mData.tableRules.sort,
              filters: this.mData.tableRules.filters,
              showFormSearch: this.mData.tableRules.showFormSearch,
            },
          })
          .catch((err) => {});
      }

      let res = await SurveyApi.list(dataFilter);
      let resData = await this.fn_changeData(res.result);
      resData.forEach((item) => {
        item.createdTime = MethodService.formatDate(
          item.createdTime,
          "datetime"
        );
      });
      //end get name
      this.mData.tableRules.data = resData;
      this.mData.tableRules.total = res.meta.total;
      this.mData.tableRules.page = res.meta.offset / res.meta.limit + 1;
      if (
        this.mData.tableRules.offset == res.meta.total &&
        this.mData.tableRules.offset != 0
      ) {
        this.mData.tableRules.offset = 0;
        this.mData.tableRules.page = 1;
        this.fn_initTable();
      }
    },

    //xử lý dữ liệu đầu ra
    async fn_changeData(resData) {
      let listSurveyId = [];

      listSurveyId = MethodService.findListItemDifferent(resData, "id");
      let reqSurveyApi = SurveyApi.list();
      let resSurveyApi = await reqSurveyApi;

      $.each(resData, (index, item) => {
        let resultSurvey = resSurveyApi.result.find((element) => {
          return element.id == item.id;
        });
        if (resultSurvey) {
          item.name = resultSurvey.name;
          item.expriedDate = MethodService.formatDate(item.expriedDate, "date");
        } else {
          item.name = "";
        }
      });

      return resData;
    },

    //click button
    fn_handle(type, scope, row) {
      if (type == "create") {
        this.$router.push({
          name: "Admin-" + this.mData.moduleName + "-create",
        });
      }
      if (type == "copy") {
        this.$router.push({
          name: "Admin-" + this.mData.moduleName + "-create",
          params: { id: row.id },
        });
      }
      if (type == "update") {
        this.$router.push({
          name: "Admin-" + this.mData.moduleName + "-update",
          params: { id: row.id },
        });
      }
      if (type == "detail") {
        this.$router.push({
          name: "Admin-" + this.mData.moduleName + "-detail",
          params: { id: row.id },
        });
      }
      if (type === "charts") {
        this.$router.push({
          name: "Admin-" + this.mData.moduleName + "-charts",
          params: { id: row.id },
        });
      }
      if (type == "delete") {
        this.$confirm(LanguageService.lang.c_deleteConfirm, {
          confirmButtonText: LanguageService.lang.c_agree,
          cancelButtonText: LanguageService.lang.c_cancel,
          type: "warning",
        })
          .then(() => {
            this.fn_delete(row.id);
          })
          .catch(() => {});
      }
      if (type == "changePass") {
        this.$router.push({
          name: "Admin-" + this.mData.moduleName + "-update",
          params: { id: row.id },
          query: {
            viewMode: "changePass",
          },
        });
      }
      if (type == "getInfo") {
        this.$router.push({
          name: "Admin-" + this.mData.moduleName + "-information",
          params: { id: row.id },
        });
      }
    },

    //delete
    async fn_delete(id) {
      await SurveyApi.delete(id);
      this.fn_initTable();
    },

    //event table
    fn_tableSelectionChange(val) {
      var _this = this;
      this.mData.tableRules.recordSelected = [];
      val.forEach(function (element) {
        _this.mData.tableRules.recordSelected.push(element.id);
      });
    },

    fn_tableSortChange(column) {
      if (column.order == "ascending") {
        this.mData.tableRules.sort = column.prop;
      } else if (column.order == "descending") {
        this.mData.tableRules.sort = "-" + column.prop;
      } else {
        this.mData.tableRules.sort = "";
      }
      this.fn_initTable();
    },
    // search
    fn_tableSearch() {
      this.$refs["myForm"].validate(async (valid) => {
        if (valid && !this.mData.dataDefault.validErrorTime) {
          this.mData.tableRules.filters = MethodService.filterTable(
            JSON.stringify(this.mData.tableRules.dataSearch)
          );
          this.mData.tableRules.offset = 0;
          this.mData.tableRules.sort = "";
          this.fn_initTable();
        } else {
          MethodService.stopLadda();
          return false;
        }
      });
    },

    //event table
    fn_tableSizeChange(limit) {
      this.mData.tableRules.limit = limit;
      this.fn_tableChangeOffset(this.mData.tableRules.page);
    },
    fn_tableCurentChange(page) {
      this.fn_tableChangeOffset(page);
    },
    fn_tablePrevClick(page) {
      this.fn_tableChangeOffset(page);
    },
    fn_tableNextClick(page) {
      this.fn_tableChangeOffset(page);
    },

    fn_tableChangeOffset(page) {
      this.mData.tableRules.page = page;
      this.mData.tableRules.offset =
        (this.mData.tableRules.page - 1) * this.mData.tableRules.limit;
      this.fn_initTable();
    },

    fn_tableShowFormSearch() {
      this.mData.tableRules.showFormSearch =
        !this.mData.tableRules.showFormSearch;
      let query = Object.assign({}, this.$route.query);
      query.showFormSearch = this.mData.tableRules.showFormSearch;
      this.$router.push({
        name: "Admin-" + this.mData.moduleName + "-list",
        query: query,
      });
    },

    async fn_findNongTrai(query) {
      if (!query) {
        return;
      }
      let res = await NongTraiApi.list({
        limit: 1000,
        filters: `ten:regex_i:${query}`,
      });
      this.mData.dataDefault.listFindNongTrai = res.result;
    },

    fn_formatterDate(row, column, cellValue, index) {
      return MethodService.formatDate(cellValue, "date");
    },

    fn_formatterDatetime(row, column, cellValue, index) {
      return MethodService.formatDate(cellValue, "datetime");
    },

    fn_formatterRoles(row, column, cellValue, index) {
      var roles = "";
      cellValue.forEach((item) => {
        for (let i = 0; i < this.mData.dataDefault.roles.length; i++) {
          if (item == this.mData.dataDefault.roles[i].value) {
            item = this.mData.dataDefault.roles[i].label;
            roles += item + " ,";
          }
        }
      });
      roles = roles.slice(0, -1);
      return roles;
    },

    async fn_hashUrl() {
      if (this.$route.query.limit) {
        this.mData.tableRules.limit = parseInt(this.$route.query.limit);
      }
      if (this.$route.query.page) {
        this.mData.tableRules.page = parseInt(this.$route.query.page);
      }
      if (this.$route.query.limit && this.$route.query.page) {
        this.mData.tableRules.offset =
          (this.mData.tableRules.page - 1) * this.mData.tableRules.limit;
      }
      if (this.$route.query.sort) {
        this.mData.tableRules.sort = this.$route.query.sort;
        this.mData.tableRules.defaultSort[0] = this.mData.tableRules.sort;
        if (this.mData.tableRules.sort.indexOf("-") != -1) {
          this.mData.tableRules.defaultSort[1] = "descending";
        } else {
          this.mData.tableRules.defaultSort[1] = "ascending";
        }
      }
      if (this.$route.query.filters) {
        this.mData.tableRules.filters = this.$route.query.filters;
        // string to object
        this.mData.tableRules.dataSearch.value = MethodService.stringToObject(
          this.mData.tableRules.filters
        );
      }
      if (this.$route.query.showFormSearch == "true") {
        this.mData.tableRules.showFormSearch = true;
      }
      if (this.$route.query.showFormSearch == "false") {
        this.mData.tableRules.showFormSearch = false;
      }
    },

    fn_compareTime() {
      let timeStart = this.mData.tableRules.dataSearch.value.thoi_gian_bat_dau;
      let timeEnd = this.mData.tableRules.dataSearch.value.thoi_gian_ket_thuc;

      if (timeStart && timeEnd) {
        let time1 = "";
        let time2 = "";

        if (typeof timeStart == "string") {
          time1 = new Date(timeStart);
        }
        if (typeof timeEnd == "string") {
          time2 = new Date(timeEnd);
        }

        if (time1.getTime() > time2.getTime()) {
          this.mData.dataDefault.validErrorTime = true;
          return LanguageService.lang.valid_compareTime;
        }
      } else {
        this.mData.dataDefault.validErrorTime = false;
        return "";
      }
    },
    async fn_changeDisabled(id, disable) {
      if (disable) {
        await AccountApi.disable(id);
        this.fn_initTable();
      } else {
        await AccountApi.undisable(id);
        this.fn_initTable();
      }
    },
  },

  //run after render view
  created() {
    this.fn_hashUrl();
  },
  mounted() {
    this.fn_initTable();
  },
};
</script>
