<template>
  <div style="width:100%">
    <!-- biểu đồ -->
    <div id="chartPie" style="height: 370px; width: 100%;"></div>
  </div>
</template>

<script>
import chartPieJS from "./ChartPie";

export default {
  name: "chart-pie",
  props: ["configChartPie","config"],
  methods: {
    displayCharts(data , config) {
      let dataPoint = this.configChartPie;
      let configOption = this.config;
      if(data)  dataPoint = data;
      if(config) configOption = config;

     chartPieJS.chartJS(dataPoint,configOption);
    }
  },
  data: function() {
    
    return {
      // idChartPie:'',
    };
  },
  mounted() {
    // this.displayCharts();
  }
};
</script>

<style scoped>
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
/* .card-body{
  min-height: 87vh;
} */
</style>
