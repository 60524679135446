<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header">
        {{aLang.c_list}} kết quả biểu mẫu
        <div class="card-header-actions"></div>
      </div>
      <div class="card-body">
        <div class="text-left">
          <b-collapse v-bind:visible="mData.tableRules.showFormSearch" id="myFormSearch">
            <b-card>
              <el-form
                ref="form"
                v-model="mData.surveyItems"
                label-width="120px"
                label-position="top"
              >
                <b-row>
                  <b-col md="3" v-for="surveyItem in mData.surveyItems" :key="surveyItem.code">
                    <el-form-item :label="surveyItem.title">
                      <el-input
                        v-if="surveyItem.inputControl=='textbox' || surveyItem.inputControl=='number' || surveyItem.inputControl=='email'"
                        v-model="mData.model[[surveyItem.code]+'textbox']"
                        placeholder="Nhập giá trị"
                      ></el-input>
                      <el-select
                        v-if="surveyItem.inputControl!='textbox' && surveyItem.inputControl!='image' && surveyItem.inputControl!='label'"
                        v-model="mData.model[surveyItem.code]"
                        placeholder="Chọn giá trị"
                        clearable
                        filterable
                        multiple
                      >
                        <template
                          v-for="(dataSurveyResult,index) in lodash.uniqWith(mData.dataSearchResult,(arrVal, othVal) => arrVal[surveyItem.code] === othVal[surveyItem.code])"
                        >
                          <el-option
                            v-if="dataSurveyResult[surveyItem.code]"
                            v-bind:key="index"
                            :label="dataSurveyResult[surveyItem.code]"
                            :value="dataSurveyResult[surveyItem.code]"
                          ></el-option>
                        </template>
                      </el-select>
                    </el-form-item>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="5"></b-col>
                  <b-col>
                    <el-form-item>
                      <el-button type="primary" @click="intTable">Tìm kiếm</el-button>
                    </el-form-item>
                  </b-col>
                </b-row>
              </el-form>
            </b-card>
          </b-collapse>
        </div>
        <b-row>
          <b-col md="7" class="p-0"></b-col>
          <b-col md="1">
            <div class="text-right mb-2">
              <el-button @click="openSelectColumns" type="info" plain>Chọn cột hiển thị</el-button>
            </div>
          </b-col>
          <b-col md="2">
            <div class="text-right mb-2">
              <el-button
                @click="fn_tableShowFormSearch()"
                type="info"
                plain
              >{{aLang.c_showHideSearch}}</el-button>
            </div>
          </b-col>
          <b-col md="1">
            <div class="text-right mb-2">
              <el-button @click="downloadFileExcel" type="info" plain>Xuất excel</el-button>
            </div>
          </b-col>
          <b-col md="1">
            <div class="text-right mb-2">
              <el-button @click="callBack()" type="info" plain>Quay lại</el-button>
            </div>
          </b-col>
        </b-row>

        <el-table
          :data="mData.surveyResults"
          border
          @selection-change="fn_tableSelectionChange"
          @sort-change="fn_tableSortChange"
          max-height="440"
          style="width: 100%"
          ref="surveyDetail"
          :default-sort="{prop: mData.tableRules.defaultSort[0], order: mData.tableRules.defaultSort[1]}"
        >
          <el-table-column
            v-for="surveyItem in mData.surveyItems"
            :key="surveyItem.code"
            :prop="surveyItem.code"
            :label="surveyItem.title"
            width="100px"
          ></el-table-column>
        </el-table>

        <b-row class="mt-2" v-if="mData.tableRules.allowPaging">
          <div class="col-md-4">
            <div
              v-if="mData.tableRules.allowSelect"
            >{{aLang.c_select}}: {{mData.tableRules.recordSelected.length}}</div>
          </div>
          <div class="col-md-8 text-right">
            <el-pagination
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
              :page-sizes="mData.tableRules.lengthMenu"
              :page-size="mData.tableRules.limit"
              :current-page="mData.tableRules.page"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="mData.tableRules.total"
              :pager-count="5"
            ></el-pagination>
          </div>
        </b-row>
      </div>
    </div>
    <el-dialog title="Tips" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div style="margin-top: 15px!important;width: 80%;margin: auto;" class="row">
        <el-checkbox
          class="col-md-6"
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          border
          size="small"
        >Check all</el-checkbox>
        <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
          <el-checkbox
            class="col-md-6"
            v-for="item in mData.surveyItems"
            :label="item.code"
            :value="item.code"
            :key="item.id"
            border
            size="medium"
          >{{item.title}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="displayColumns">Ok</el-button>
        <el-button @click="dialogVisible = false">Hủy bỏ</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const cityOptions = ["Shanghai", "Beijing", "Guangzhou", "Shenzhen"];
import LanguageService from "../../../service/LanguageService";
import MethodService from "../../../service/MethodService";
import AccountModel from "./SurveyModel";
import SurveyItemApi from "../../../moduleApi/SurveyItemApi";
import SurveyResultApi from "../../../moduleApi/SurveyResultApi";
import ExportExcel from "../../../component/ExportExcel.js";

import _ from "lodash";
export default {
  data() {
    return {
      checkAll: false,
      checkedCities: [],
      cities: cityOptions,
      isIndeterminate: true,

      lodash: _,
      dialogVisible: false,
      aRole: MethodService.checkRole,
      aLang: LanguageService.lang,
      aElement: MethodService.element,
      mData: {
        configXlsx: { nameFile: "surveyDetail" },
        filters: "",
        model: {},
        surveyResults: [],
        surveyItems: [],
        dataSearchResult: [],
        moduleName: "Survey", //requied
        tableRules: MethodService.copyObject(AccountModel.tableRules),
        dataDefault: {
          listFindNongTrai: []
        }
      }
    };
  },

  methods: {
    // XLSX
    downloadFileExcel() {
      //  ExportExcel.tableToExcel("tableDotNhienLieu", this.mData.configXlsx );
      let config = this.mData.configXlsx;
      config.width = 25;
      ExportExcel.tableCumtomer(this.$refs.surveyDetail, config);
    },
    //take data survey result
    async GetSurveyResult(dataFilter) {
      let res = [];
      try {
        res = await SurveyResultApi.list(dataFilter);
        return res;
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    //take data survey item
    async GetSurveyItem(surveyId, code) {
      if (surveyId) {
        try {
          let params = {
            filters: `surveyId=${surveyId}`
          };

          let res = await SurveyItemApi.list(params);
          return res.result;
        } catch (error) {
          console.log(error);
          return [];
        }
      } else {
        return [];
      }
    },
    // convert data survey result so sanh data survey item
    async filterSurveyResult(surveyItems, surveyResults) {
      let dataResults = [];
      if (
        surveyItems &&
        surveyItems.length > 0 &&
        surveyResults &&
        surveyResults.length > 0
      ) {
        let newSurveyResults1 = [];
        // tách surveyResults lấy result gép vào mảng
        surveyResults.forEach(surveyResult => {
          newSurveyResults1.push(surveyResult.result);
        });
        //tách newSurveyResults1 trả về newSurveyResults2 và mỗi newSurveyResults2 là một mảng
        newSurveyResults1.forEach(newSurveyResults2 => {
          const filteredArray = newSurveyResults2.filter(x =>
            surveyItems.find(y => y.code === x.code && y.id === x.surveyItemId)
          );
          const reduceResult = filteredArray.reduce(function(
            accumulator,
            currentValue
          ) {
            const newObject = {};
            newObject[currentValue.code] = currentValue.value;
            return Object.assign(accumulator, newObject);
          },
          {});
          dataResults.push(reduceResult);
        });
        return dataResults;
      }
    },
    IsRegex(operator, value) {
      const regex = new RegExp(operator);

      return regex.test(value);
    },

    //get list table
    async intTable() {
      let surveyItems = await this.GetSurveyItem(
        this.$router.currentRoute.params.id
      );
      const arrNewsItems = surveyItems.filter(surveyItem => {
        if (
          !(
            surveyItem.inputControl == "label" ||
            surveyItem.inputControl == "image"
          )
        )
          return surveyItem;
      });

      let filters = [];
      try {
        const objectArray = Object.entries(this.mData.model);

        objectArray.forEach(([key, value]) => {
          if (this.IsRegex(/\w+textbox$/gm, key) && value) {
            key = key.replace(/textbox$/gi, "");
            filters.push(`result:elemMatch:code=${key},value=${value}`);
          }
          if (value && Array.isArray(value) && value.length > 0) {
            filters.push(`result:elemMatch:code=${key},value:in:${value}`);
          }
        });
      } catch (e) {
        console.log(e);
      }
      this.mData.filters = filters;

      let dataFilter = {
        page: this.mData.tableRules.page,
        offset: this.mData.tableRules.offset,
        limit: this.mData.tableRules.limit,
        filters: filters
      };
      if (this.mData.tableRules.showUrl) {
        this.$router
          .replace({
            name: "Admin-" + this.mData.moduleName + "-detail",
            query: {
              limit: this.mData.tableRules.limit,
              page: this.mData.tableRules.page,
              offset: this.mData.tableRules.offset,
              filters: filters,
              showFormSearch: this.mData.tableRules.showFormSearch
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
      // ["result.code=col_hoten", "result.value:like:%Hà%"]
      let resSR = await this.GetSurveyResult(dataFilter);
      let surveyResults = resSR.result;

      let dataSurveyResults = await this.filterSurveyResult(
        surveyItems,
        surveyResults
      );
      let { result: searchsurveyResults } = await this.GetSurveyResult({
        limit: 10000
      });
      let dataSearchResult = await this.filterSurveyResult(
        surveyItems,
        searchsurveyResults
      );
      //dataSearch
      this.mData.dataSearchResult = dataSearchResult;
      //dataItem(key)
      this.mData.surveyItems = arrNewsItems;
      //dataTable
      this.mData.surveyResults = dataSurveyResults;

      try {
        this.mData.tableRules.total = resSR.meta.total;
        this.mData.tableRules.page = resSR.meta.offset / resSR.meta.limit + 1;
        if (
          this.mData.tableRules.offset == resSR.meta.total &&
          resSR.meta.total != 0
        ) {
          this.mData.tableRules.offset = 0;
          this.mData.tableRules.page = 1;
          this.intTable();
        }
      } catch (e) {
        console.log(e);
      }
    },
    displayColumns() {
      let checked = this.checkedCities;
      let surveyItem = this.mData.surveyItems;
      if (checked && checked.length < 1) {
        this.$alert("Bạn chưa chọn trường nào hiển thị", {
          confirmButtonText: "OK"
        });
      } else {
        if (surveyItem && surveyItem.length > 0) {
          surveyItem = surveyItem.filter(x => {
            return (x.code = checked.find(y => y === x.code));
          });
          this.mData.surveyItems = surveyItem;
          // this.intTable();
        }
        this.dialogVisible = false;
      }
    },
    openSelectColumns() {
      this.dialogVisible = true;
      this.checkedCities = [];
    },
    handleClose(done) {
      done();
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    fn_tableSelectionChange() {},
    fn_tableSortChange(column) {
      if (column.order == "ascending") {
        this.mData.tableRules.sort = column.prop;
      } else if (column.order == "descending") {
        this.mData.tableRules.sort = "-" + column.prop;
      } else {
        this.mData.tableRules.sort = "";
      }
      this.intTable();
    },
    callBack() {
      this.$router.push({ name: "Admin-Survey-list" });
    },

    //event table
    fn_tableSizeChange(limit) {
      this.mData.tableRules.limit = limit;
      this.fn_tableChangeOffset(this.mData.tableRules.page);
    },
    fn_tableCurentChange(page) {
      this.fn_tableChangeOffset(page);
    },
    fn_tablePrevClick(page) {
      this.fn_tableChangeOffset(page);
    },
    fn_tableNextClick(page) {
      this.fn_tableChangeOffset(page);
    },

    fn_tableChangeOffset(page) {
      this.mData.tableRules.page = page;
      this.mData.tableRules.offset =
        (this.mData.tableRules.page - 1) * this.mData.tableRules.limit;
      this.intTable();
    },

    fn_tableShowFormSearch() {
      this.mData.tableRules.showFormSearch = !this.mData.tableRules
        .showFormSearch;
      let query = Object.assign({}, this.$route.query);
      query.showFormSearch = this.mData.tableRules.showFormSearch;
      this.$router.push({
        name: "Admin-" + this.mData.moduleName + "-detail",
        query: query
      });
    },

    async fn_hashUrl() {
      if (this.$route.query.limit) {
        this.mData.tableRules.limit = parseInt(this.$route.query.limit);
      }
      if (this.$route.query.page) {
        this.mData.tableRules.page = parseInt(this.$route.query.page);
      }
      if (this.$route.query.limit && this.$route.query.page) {
        this.mData.tableRules.offset =
          (this.mData.tableRules.page - 1) * this.mData.tableRules.limit;
      }
      if (this.$route.query.sort) {
        this.mData.tableRules.sort = this.$route.query.sort;
        this.mData.tableRules.defaultSort[0] = this.mData.tableRules.sort;
        if (this.mData.tableRules.sort.indexOf("-") != -1) {
          this.mData.tableRules.defaultSort[1] = "descending";
        } else {
          this.mData.tableRules.defaultSort[1] = "ascending";
        }
      }
      if (this.$route.query.showFormSearch == "true") {
        this.mData.tableRules.showFormSearch = true;
      }
      if (this.$route.query.showFormSearch == "false") {
        this.mData.tableRules.showFormSearch = false;
      }
      // if (this.$route.query.filters) {

      // }
    }
  },

  //run after render view
  created() {
    this.fn_hashUrl();
  },
  mounted() {
    this.intTable();
  }
};
</script>
<style >
.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0px;
}
.el-checkbox-group.row {
  margin-left: 0px;
}
.el-table--small td,
.el-table--small th {
  padding: 6px 0px;
}
</style>
