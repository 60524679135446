<template>
  <div class="animated fadeIn" id="Form-survey-information">
    <div class="card">
      <div class="card-header text-center bold">
        {{ aLang.c_surveyInfoTitle }}
      </div>
      <div class="card-body text-center">Thông tin hữu ích cần lưu ý</div>
    </div>
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col md="8">
            <div class="card">
              <header class="card-header">
                <label class="width-100"><span> Host URL</span></label>
              </header>
              <div class="card-body">
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <el-input
                        class="width-100 pl-2"
                        id="hostUrl"
                        readonly
                        :value="mData.dataDefault.hostUrl"
                      ></el-input>
                      <i
                        class="far fa-copy size-icon-copy cursor-pointer pl-2"
                        @click="fn_copyHostUrl"
                      ></i>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <div class="card">
              <header class="card-header">
                <label
                  class="width-100 cursor-pointer mb-0"
                  @click="fn_changeCustomParamStatus"
                  ><span>{{
                    mData.dataDefault.customParam.mark +
                    mData.dataDefault.customParam.name
                  }}</span></label
                >
              </header>
              <div class="card-body" v-if="mData.dataDefault.isShowCustomParam">
                <div>
                  <el-form
                    :model="mData.dataDefault.surveyFormDefault"
                    :rules="mData.applicationFormDefault"
                    ref="myForm"
                    class="w-100"
                    label-width="50%"
                    label-position="top"
                  >
                    <div class="pl-2 mb-1">
                      <label
                        class="width-100 list-group-item list-group-item-info"
                      >
                        <span>PrefillData</span>
                      </label>
                      <div class="pl-3 pr-3 background-color-gray pt-2 w-100">
                        <b-row>
                          <b-col md="3">
                            <el-form-item label="Mã cột">
                              <el-select
                                v-model="
                                  mData.dataDefault.surveyFormDefault.code
                                "
                                placeholder="Select"
                              >
                                <el-option
                                  v-for="item in mData.dataForm.surveyItems"
                                  :key="item.code"
                                  :label="item.code"
                                  :value="item.code"
                                >
                                </el-option>
                              </el-select>
                            </el-form-item>
                          </b-col>
                          <b-col md="5">
                            <el-form-item label="Giá trị">
                              <el-input
                                type="textarea"
                                autosize
                                v-model="
                                  mData.dataDefault.surveyFormDefault.value
                                "
                              ></el-input>
                            </el-form-item>
                          </b-col>
                          <b-col md="4">
                            <el-form-item label="Thêm">
                              <el-button
                                type="primary"
                                @click="fn_addToPrefillDataAndTable"
                                >Xác nhận</el-button
                              >
                            </el-form-item>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md="12" class="pb-2">
                            <el-table
                              :data="mData.dataForm.prefillDataResult"
                              border
                              stripe
                              class="text-left w-100 justify-content-center"
                            >
                              <el-table-column
                                align="center"
                                label="STT"
                                type="index"
                                width="100"
                              >
                              </el-table-column>
                              <el-table-column
                                align="left"
                                prop="code"
                                label="Mã câu hỏi"
                                width="auto"
                              >
                              </el-table-column>
                              <el-table-column
                                align="left"
                                prop="value"
                                label="Dữ liệu"
                                width="auto"
                              >
                              </el-table-column>
                              <el-table-column
                                :label="aLang.c_action"
                                align="center"
                                width="auto"
                              >
                                <template slot-scope="scope">
                                  <el-button
                                    size="mini"
                                    v-b-popover.hover.left="aLang.c_delete"
                                    @click="
                                      fn_handlePrefillData(
                                        'delete',
                                        scope.$index
                                      )
                                    "
                                  >
                                    <i class="fa-light fa-trash-can-xmark"></i>
                                  </el-button>
                                  <el-button
                                    size="mini"
                                    v-b-popover.hover.left="aLang.c_update"
                                    @click="
                                      fn_handlePrefillData(
                                        'update',
                                        scope.$index
                                      )
                                    "
                                  >
                                    <i class="fa-light fa-pen"></i>
                                  </el-button>
                                </template>
                              </el-table-column>
                            </el-table>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </el-form>
                </div>
                <div class="pl-2 mb-1">
                  <label class="width-100 list-group-item list-group-item-info">
                    <span>Application</span>
                  </label>
                  <div class="pl-3 pr-3 background-color-gray pt-2 w-100">
                    <el-form
                      :model="mData.dataDefault.applicationFormDefault"
                      :rules="mData.dataForm.applicationFormDefault"
                      ref="myFormApplication"
                      class="w-100"
                      label-width="50%"
                      label-position="top"
                    >
                      <b-row>
                        <b-col md="3">
                          <el-form-item label="appCode">
                            <el-input
                              class="width-100 pl-2"
                              readonly
                              :value="mData.dataForm.application.code"
                            ></el-input>
                          </el-form-item>
                        </b-col>
                        <b-col md="3">
                          <el-form-item label="env" prop="env">
                            <el-select
                              v-model="
                                mData.dataDefault.applicationFormDefault.env
                              "
                              placeholder="Select"
                            >
                              <el-option
                                v-for="item in mData.dataForm.application
                                  .environments"
                                :key="item.envType"
                                :label="item.envType"
                                :value="item.envType"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </b-col>
                        <b-col md="3">
                          <el-form-item label="callBackUrl" prop="callBackUrl">
                            <el-input
                              class="width-100 pl-2"
                              v-model="
                                mData.dataDefault.applicationFormDefault
                                  .callBackUrl
                              "
                            ></el-input>
                          </el-form-item>
                        </b-col>
                      </b-row>
                    </el-form>
                  </div>
                </div>

                <div class="pl-2 mb-1">
                  <label class="width-100 list-group-item list-group-item-info">
                    <span>ViewMode</span>
                  </label>
                  <div class="pl-3 pr-3 background-color-gray pt-2 w-100">
                    <el-form
                      :model="mData.dataDefault.viewModeFormDefault"
                      :rules="mData.validFormRender"
                      ref="myForm"
                      class="w-100"
                      label-width="50%"
                      label-position="top"
                    >
                      <b-row>
                        <b-col md="6">
                          <el-form-item label="viewMode">
                            <el-select
                              v-model="
                                mData.dataDefault.viewModeFormDefault.viewMode
                              "
                              placeholder="Select"
                            >
                              <el-option
                                key="create"
                                label="Thêm"
                                value="create"
                              ></el-option>
                              <el-option
                                key="update"
                                label="Sửa"
                                value="update"
                              ></el-option>
                              <el-option
                                key="view"
                                label="Xem"
                                value="view"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </b-col>
                      </b-row>
                    </el-form>
                  </div>
                </div>
                <div class="pl-2 mb-1 text-center">
                  <el-button type="primary" @click="fn_generateHostUrl"
                    >Tạo Host Url</el-button
                  >
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="8">
            <div class="card mt-4">
              <header class="card-header">
                <label
                  class="width-100 cursor-pointer mb-0"
                  @click="fn_changeParamStatus"
                  ><span>{{
                    mData.dataDefault.params.mark +
                    mData.dataDefault.params.name
                  }}</span></label
                >
              </header>
              <div class="card-body" v-if="mData.dataDefault.isShowParams">
                <div
                  class="pl-2 mb-1"
                  v-for="(item, index) in mData.dataDefault.params.elements"
                >
                  <label
                    class="width-100 cursor-pointer list-group-item list-group-item-info"
                    @click="fn_handleChange(index)"
                  >
                    <span>{{ item.mark + item.name }}</span>
                  </label>
                  <div
                    v-if="item.isShowDescription"
                    class="pl-3 background-color-gray pt-2"
                  >
                    <label class="w-100">
                      <span
                        >{{ aLang.c_description }}
                        {{ item.description.titleDescription }}</span
                      >
                    </label>
                    <label class="w-100">
                      <span>{{ item.description.detailDescription.name }}</span>
                    </label>
                    <label
                      class="w-100 pl-3"
                      v-for="item1 in item.description.detailDescription.data"
                    >
                      <span>{{ item1 }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageService from "../../../service/LanguageService";
import "../../../assets/scss/layout/_surveyInfo.scss";
import LabelWrap from "element-ui/packages/form/src/label-wrap";
import SurveyItemApi from "../../../moduleApi/SurveyItemApi";
import ApplicationApi from "../../../moduleApi/ApplicationApi";
import SurveyApi from "../../../moduleApi/SurveyApi";

import MethodService from "../../../service/MethodService";
import SurveyModel from "./SurveyModel";
import mushroom from "../../../service/api/mushroom-api";

export default {
  components: { LabelWrap },
  data() {
    return {
      aLang: LanguageService.lang,
      mData: {
        dataDefault: {
          hostUrl:
            "https://survey.siten.vn/s/" +
            this.$router.currentRoute.params.id +
            "?prefillData=&callbackUrl=&appCode=&env=&hashCode=&viewMode=",
          params: {
            mark: "+",
            name: " Mô tả các tham số",
            elements: [
              {
                mark: "+",
                name: " prefillData",
                description: {
                  titleDescription: "Dữ liệu được mã hóa bằng base64.",
                  detailDescription: {
                    name: "Dữ liệu chưa mã hóa có dạng: Object",
                    data: [
                      "surveyToken:string,",
                      "result:[{code:string,value:string}],",
                      "statusCallBack:boolean",
                    ],
                  },
                },
                isShowDescription: false,
              },
              {
                mark: "+",
                name: " appCode",
                description: {
                  titleDescription: "Là Application Id.",
                  detailDescription: {
                    name: "Kiểu dữ liệu: string",
                    data: [],
                  },
                },
                isShowDescription: false,
              },
              {
                mark: "+",
                name: " env",
                description: {
                  titleDescription: "Môi trường của Application.",
                  detailDescription: {
                    name: "Kiểu dữ liệu: string",
                    data: [],
                  },
                },
                isShowDescription: false,
              },
              {
                mark: "+",
                name: " callbackUrl",
                description: {
                  titleDescription:
                    "Là một trường trong environments của Application, Url sẽ được gọi lại khi thành công.",
                  detailDescription: {
                    name: "Kiểu dữ liệu: string",
                    data: [],
                  },
                },
                isShowDescription: false,
              },
              {
                mark: "+",
                name: " privateKey",
                description: {
                  titleDescription:
                    "Là một trường trong environments của Application, Khóa bí mật được cung cấp.",
                  detailDescription: {
                    name: "Kiểu dữ liệu: string",
                    data: [],
                  },
                },
                isShowDescription: false,
              },
              {
                mark: "+",
                name: " hashCode",
                description: {
                  titleDescription:
                    "Dữ liệu được mã hóa bằng SHA256, trường nào không có có thể để trống và được ngăn cách bơi dấu ','",
                  detailDescription: {
                    name: "Dữ liệu chưa được mã hóa có dạng: string",
                    data: [
                      "privateKey+','+prefillData+','+callbackUrl+','+appCode+','+env",
                    ],
                  },
                },
                isShowDescription: false,
              },
              {
                mark: "+",
                name: " viewMode",
                description: {
                  titleDescription: "Chế độ thao tác với form",
                  detailDescription: {
                    name: "Kiểu dữ liệu: string",
                    data: [],
                  },
                },
                isShowDescription: false,
              },
            ],
          },
          isShowParams: false,
          customParam: {
            mark: "+",
            name: " Thêm thông tin tham số",
          },
          surveyFormDefault: {
            code: "",
            value: "",
          },
          applicationFormDefault: {
            appCode: "",
            env: "",
            privateKey: "",
            callBackUrl: "",
          },
          viewModeFormDefault: { viewMode: "view" },

          isShowCustomParam: false,
        },
        dataForm: {
          surveyItems: [],
          application: {},
          prefillDataResult: [],
          prefillData: {},
          hashCodeObject: MethodService.copyObject(
            SurveyModel.dataForm.hashCodeObject
          ),
          applicationFormDefault: MethodService.copyObject(
            SurveyModel.validForm.applicationFormDefault
          ),
        },
      },
    };
  },
  methods: {
    fn_handleChange(index) {
      if (index || index === 0) {
        if (this.mData.dataDefault.params.elements[index].isShowDescription) {
          this.mData.dataDefault.params.elements[
            index
          ].isShowDescription = false;
          this.mData.dataDefault.params.elements[index].mark = "+";
          return;
        }
        if (!this.mData.dataDefault.params.elements[index].isShowDescription) {
          this.mData.dataDefault.params.elements[
            index
          ].isShowDescription = true;
          this.mData.dataDefault.params.elements[index].mark = "-";
        }
      }
    },
    fn_changeParamStatus() {
      if (this.mData.dataDefault.isShowParams) {
        this.mData.dataDefault.isShowParams = false;
        this.mData.dataDefault.params.mark = "+";
        return;
      }
      if (!this.mData.dataDefault.isShowParams) {
        this.mData.dataDefault.isShowParams = true;
        this.mData.dataDefault.params.mark = "-";
      }
    },
    fn_changeCustomParamStatus() {
      if (this.mData.dataDefault.isShowCustomParam) {
        this.mData.dataDefault.isShowCustomParam = false;
        this.mData.dataDefault.customParam.mark = "+";
        return;
      }
      if (!this.mData.dataDefault.isShowCustomParam) {
        this.mData.dataDefault.isShowCustomParam = true;
        this.mData.dataDefault.customParam.mark = "-";
      }
    },
    fn_copyHostUrl() {
      var copyText = document.getElementById("hostUrl");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      toastr.success("Sao chép thành công!");
    },
    async fn_loadSurveyItems(surveyId) {
      let req_SurveyItem = SurveyItemApi.list({
        filters: "surveyId=" + surveyId,
      });
      let res_Survey = await req_SurveyItem;
      this.mData.dataForm.surveyItems = res_Survey.result
        ? res_Survey.result
        : [];
      console.log("SurveyItem", this.mData.dataForm.surveyItems);
    },
    async fn_loadApplicationBySurveyId(surveyId) {
      let req_Survey = SurveyApi.findById(surveyId);
      let res_Survey = await req_Survey;

      let req_Application = ApplicationApi.findById(
        res_Survey.result.applicationId
      );
      let res_Application = await req_Application;
      this.mData.dataForm.application = res_Application.result
        ? res_Application.result
        : [];
      console.log("application", this.mData.dataForm.application);
    },
    fn_addToPrefillDataAndTable() {
      let isUpdate = false;
      let surveyFormDefault = MethodService.copyObject(
        this.mData.dataDefault.surveyFormDefault
      );
      let prefillData = MethodService.copyObject(
        this.mData.dataForm.prefillData
      );
      let prefillDataResult = MethodService.copyObject(
        this.mData.dataForm.prefillDataResult
      );
      for (let i = 0; i < prefillDataResult.length; i++) {
        if (surveyFormDefault.code == prefillDataResult[i].code) {
          prefillDataResult[i] = surveyFormDefault;
          isUpdate = true;
          break;
        }
      }
      if (!isUpdate) {
        prefillDataResult.push(surveyFormDefault);
      }
      prefillData[surveyFormDefault.code] = surveyFormDefault.value;
      this.mData.dataForm.prefillDataResult = prefillDataResult;
      this.mData.dataForm.prefillData = prefillData;
      this.fn_resetForm();
    },
    fn_resetForm() {
      this.$refs["myForm"].resetFields();
      this.mData.dataDefault.surveyFormDefault = {};
    },
    async fn_generateHostUrl() {
      this.$refs["myFormApplication"].validate(async (valid) => {
        if (valid) {
          let hashCodeObject = this.fn_getHashCodeObject();
          try {
            let responseHashCode = await mushroom.survey.generateHashCodeAsync(
              hashCodeObject
            );
            let hashCodeBum = responseHashCode.result;
            let prefillDataBum = this.fn_getPrefillDataBum();
            let application = this.fn_getApplicationFormDefault();
            let appCode = application.appCode;
            let env = application.env;
            let callbackUrl = application.callBackUrl;
            let viewMode = this.mData.dataDefault.viewModeFormDefault.viewMode;
            let hostUrlGenerate =
              "https://survey.siten.vn/s/" +
              this.$router.currentRoute.params.id +
              "?prefillData=" +
              prefillDataBum +
              "&callbackUrl=" +
              callbackUrl +
              "&appCode=" +
              appCode +
              "&env=" +
              env +
              "&hashCode=" +
              hashCodeBum +
              "&viewMode=" +
              viewMode;
            this.mData.dataDefault.hostUrl = hostUrlGenerate;
            console.log("prefillDataBum", prefillDataBum);
            console.log("appCode", appCode);
            console.log("callbackUrl", callbackUrl);
            console.log("env", env);
            console.log("viewMode", viewMode);
            console.log("hostUrlGenerate", hostUrlGenerate);
            toastr.success("Tạo URL thành công!");
          } catch (error) {
            toastr.error("Không thể tạo được HashCode!");
          }
        }
      });
    },
    fn_handlePrefillData(type, index) {
      console.log("index", index);
      if (type == "update") {
        let item = MethodService.copyObject(
          this.mData.dataForm.prefillDataResult[index]
        );
        let surveyFormDefault = MethodService.copyObject(
          this.mData.dataDefault.surveyFormDefault
        );
        surveyFormDefault.code = item.code;
        surveyFormDefault.value = item.value;
        this.mData.dataDefault.surveyFormDefault = surveyFormDefault;
      }
      if (type == "delete") {
        let item = MethodService.copyObject(
          this.mData.dataForm.prefillDataResult[index]
        );
        this.mData.dataForm.prefillDataResult.splice(index, 1);
        delete this.mData.dataForm.prefillData[item.code];
        this.fn_resetForm();
      }
    },
    fn_getApplicationFormDefault() {
      let applicationFormDefault = MethodService.copyObject(
        this.mData.dataDefault.applicationFormDefault
      );
      let application = MethodService.copyObject(
        this.mData.dataForm.application
      );
      applicationFormDefault.appCode = application.code;
      for (let i = 0; i < application.environments.length; i++) {
        if (applicationFormDefault.env == application.environments[i].envType) {
          applicationFormDefault.privateKey =
            MethodService.isUndefined(application.environments[i].privateKey) ||
            MethodService.isNull(application.environments[i].privateKey)
              ? ""
              : application.environments[i].privateKey;
        }
      }
      console.log("applicationFormDefault", applicationFormDefault);
      return applicationFormDefault;
    },
    fn_getPrefillDataBum() {
      let prefillData = MethodService.copyObject(
        this.mData.dataForm.prefillData
      );
      console.log("isEmpty", MethodService.isEmptyObject(prefillData));
      if (!MethodService.isEmptyObject(prefillData)) {
        let prefillDataJson = JSON.stringify(prefillData);
        return MethodService.encode(prefillDataJson);
      }
      return "";
    },
    fn_getHashCodeObject() {
      let hashCodeObject = MethodService.copyObject(
        this.mData.dataForm.hashCodeObject
      );
      let applicationFormDefault = this.fn_getApplicationFormDefault();
      hashCodeObject.prefillData = this.fn_getPrefillDataBum();
      hashCodeObject.privateKey = applicationFormDefault.privateKey;
      hashCodeObject.callbackUrl = applicationFormDefault.callBackUrl;
      hashCodeObject.appCode = applicationFormDefault.appCode;
      hashCodeObject.env = applicationFormDefault.env;
      return hashCodeObject;
    },
  },
  created: function () {
    if (this.$router.currentRoute.params.id) {
      this.fn_loadSurveyItems(this.$router.currentRoute.params.id);
      this.fn_loadApplicationBySurveyId(this.$router.currentRoute.params.id);
    }
  },
};
</script>

<style scoped></style>
