import ExtensionService from './ExtensionService';
import * as moment from 'moment';
import readXlsxFile from 'read-excel-file';
import {Base64} from 'js-base64';

let MethodService = {};

MethodService.getToken = function () {
  return window.localStorage.getItem('mushroom.tokens[' + window.MyConfig.hostApi + ']');
};

MethodService.checkRole = function (stringRoles) {
  var currentUser = JSON.parse(window.localStorage.currentUser);
  let roles = stringRoles.split(",");
  var flag = false;
  roles.forEach(function (item) {
    item = item.trim();
    if (currentUser.roles.indexOf(item) != -1) {
      flag = true;
    }
  })
  return flag;
};

MethodService.getCurrentUser = function () {
  return JSON.parse(window.localStorage.currentUser);
};

MethodService.element = {
  datepicker: {

    formatDate: "dd/MM/yyyy",
    formatDatetime: "dd/MM/yyyy HH:mm",
    valueFormat: "yyyy-MM-ddTHH:mm:ss",
    placeholderDate: "Ngày/Tháng/Năm",
    placeholderDatetime: "Ngày/Tháng/Năm Giờ:Phút",

    formatMonth: "MM/yyyy",
    placeholderMonth: "Tháng/Năm",
    formatYear: "yyyy",
    placeholderYear: "Năm",

  }
};

//required iso 9001 ex: yyyy-dd-mmThh:mm:ssZ
let formatDateMoment = "DD/MM/YYYY";

//ex: iso date to string date  yyyy-dd-mmThh:mm:ssZ - > DD/MM/YYYY
//ex: new Date to iso date  Wed Mar 25 2015 07:00:00 GMT+0700 (Giờ Đông Dương) - > yyyy-dd-mmThh:mm:ssZ || MethodService.formatDate(new Date, "")
MethodService.formatDate = function (value, rule) {
  if (value) {
    var format = "";
    if (rule == "date") {
      format = formatDateMoment;
    } else if (rule == "datetime") {
      format = formatDateMoment + " HH:mm";

    } else if (rule == "aboutTime") {
      format = formatDateMoment + " HH:mm";
    } else {
      format = rule;
    }
    return moment(value).format(format);
  } else {
    return "";
  }
};

MethodService.formatTimestamp = function (value, rule) {
  if (value) {
    var format = "";
    if (rule == "date") {
      format = formatDateMoment;
    } else if (rule == "datetime") {
      format = formatDateMoment + " HH:mm";

    } else if (rule == "aboutTime") {
      format = formatDateMoment + " HH:mm";
    } else {
      format = rule;
    }
    return moment(value, 'YYYY:MM:DDTHH:mm:ss.SSSZZ').format(format);
  } else {
    return "";
  }
};

//value mush is "DD/MM/YYYY"
MethodService.formatStringToIsoDate = function (value) {
  return moment(value, "DD/MM/YYYY").format();
};

MethodService.checkIsoDate = function (value) {
  return RegExp(/^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/).test(value);
};

MethodService.getAboutTime = function (value) {
  if (value) {
    let timeAgo = moment(value).fromNow();
    timeAgo = timeAgo.replace("a few seconds ago", "vài giây trước").replace("minutes ago", "phút trước").replace("hours ago", "giờ trước");
    return timeAgo;
  } else {
    return "";
  }
};

//value mush is Iso date
MethodService.getDay = function (value) {
  if (value) {
    return moment(value, "YYYY-MM-DD").get('date');
  } else {
    return "";
  }
};
MethodService.getMonth = function (value) {
  if (value) {
    return (1 + moment(value, "YYYY-MM-DD").get('month'));
  } else {
    return "";
  }
};
MethodService.getYear = function (value) {
  if (value) {
    return moment(value, "YYYY-MM-DD").get('year');
  } else {
    return "";
  }
};

//ex: MethodService.findItem(listProvince, id, "123"); return item;
MethodService.findItem = function (arr, attrFind, val) {
  arr.find(item => {
    return val == item[attrFind];
  })
};

MethodService.addDate = function (value, type, numberTime) {
  if (!value) {
    return "";
  }
  return moment(value).add(numberTime, type).format("YYYY-MM-DDTHH:mm:ss");
};

MethodService.formatterTrimString = function (row, column, cellValue, index, number) {
  if (!number) {
    number = 8;
  }
  var lengthAllow = (column.width ? column.width : column.realWidth) / number;
  if (cellValue) {
    if (cellValue.length > lengthAllow) {
      return cellValue.slice(0, lengthAllow) + "...";
    } else {
      return cellValue;
    }
  } else {
    return "";
  }
};

MethodService.formatterFloatNumber = function (row, column, cellValue, index) {
  if (cellValue) {
    cellValue = parseFloat(cellValue).toFixed(2);
    return cellValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
};

MethodService.stringToObject = function (value) {
  return JSON.parse(
    '{"' +
    decodeURI(value)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/<=/g, '":"')
      .replace(/>=/g, '":"')
      .replace(/=/g, '":"')
      .replace(/:regex_i:/g, '":"')
      .replace(/:regex:/g, '":"')
      .replace(/</g, '":"')
      .replace(/>/g, '":"')
    +
    '"}'
  );
};

MethodService.CalculationYear = function (type, last, next) {
  var toGo = "";
  var yearNow = new Date();
  var limit = yearNow.getFullYear();
  if (type == "last") {
    toGo = last;
  }
  if (type == "next") {
    toGo = next;
    limit = limit + next - 1;
  }
  if (type == "last-next") {
    toGo = next + last;
    limit = limit + next - 1;
  }
  var arr = [];
  for (let i = 0; i < toGo; i++) {
    arr.push(limit - i);
  }
  return arr;
};

MethodService.beforeAvatarUpload = function (file, mb) {
  return ((file.size / 1024) / 1024) < mb;
};

MethodService.EndYearBind = function (startYear) {
  let arr = [];
  let setYear = new Date();
  let yearPresent = setYear.getFullYear();

  if (startYear) {
    let rangToYearStart = yearPresent - startYear;
    if (rangToYearStart <= 20) {
      for (let i = rangToYearStart; i >= 1; i--) {
        arr.push(startYear + i);
      }
    } else {
      for (let i = 20; i <= 1; i) {
        arr.push(startYear + i);
      }
    }
  } else {
    for (let i = 0; i < 20; i++) {
      arr.push(yearPresent - i);
    }
  }

  return arr;
};

MethodService.StartYearBind = function (endYear) {
  let arr = [];
  let setYear = new Date();
  let yearPresent = setYear.getFullYear();
  if (endYear) {
    let rangToYearStart = endYear - Number(2000);
    if (rangToYearStart <= 20)
      for (let i = 1; i < rangToYearStart; i++) {
        arr.push(endYear - i);
      }
  } else {
    for (let i = 1; i < 20; i++) {
      arr.push(yearPresent - i);
    }
  }
  return arr;
};

MethodService.stopLadda = function () {
  setTimeout(function () {
    Ladda.stopAll();
  }, 100);
};

MethodService.checkFileSize = function (file, mb) {
  return ((file.size / 1024) / 1024) < mb;
};

MethodService.isString = function (value) {
  return typeof value === 'string' || value instanceof String;
};

MethodService.isNumber = function (value) {
  return typeof value === 'number' && isFinite(value);
};

MethodService.isArray = function (value) {
  return value && typeof value === 'object' && value.constructor === Array;
};

MethodService.isFunction = function (value) {
  return typeof value === 'function';
};

MethodService.isObject = function (value) {
  return value && typeof value === 'object' && value.constructor === Object;
};

MethodService.isDate = function (value) {
  return value instanceof Date;
};

MethodService.isBoolean = function (value) {
  return typeof value === 'boolean';
};

MethodService.isNull = function (value) {
  return value === null;
};

MethodService.isUndefined = function (value) {
  return typeof value === 'undefined';
};

MethodService.checkIsDate = function (day, month, year) {

  var d = parseInt(day);
  var m = parseInt(month);
  var y = parseInt(year);

  var date = new Date(y, m - 1, d);
  if (date.getFullYear() == y && date.getMonth() + 1 == m && date.getDate() == d) {
    return true
  }
  return false

};

MethodService.copyObject = function (value) {
  return JSON.parse(JSON.stringify(value));
};


// so sánh 2 object khởi tạo và object trả về, nếu trả về thiếu trường thì lấy trường từ object khởi tạo gán vào objetc trả về
MethodService.checkDifferentObject = function (objectOld, objectNew) {
  function forObject(objectOld) {
    $.each(objectOld, function (key_objectOld, value_objectOld) {
      if (MethodService.isUndefined(objectNew[key_objectOld])) {
        objectNew[key_objectOld] = objectOld[key_objectOld];
      } else if (MethodService.isNull(objectNew[key_objectOld])) {
        objectNew[key_objectOld] = objectOld[key_objectOld];
      } else {
        if (MethodService.isObject(value_objectOld)) {
          forObject(value_objectOld);
        }
      }
    });
  }

  forObject(objectOld);
  return objectNew;

};

// Tìm các phần tử khác nhau có trong mảng object trả về [],
// nếu truyền [object] thì truyển thêm field
// nếu truyền array thì k cần
MethodService.findListItemDifferent = function (arrItem, field) {
  let arrItemNew = [];
  if (field) {
    arrItem.forEach((item, index) => {
      if (field) {
        if (item[field]) {
          if (arrItemNew.indexOf(item[field]) == -1) {
            arrItemNew.push(item[field]);
          }
        }
      } else {
        if (arrItemNew.indexOf(item) == -1) {
          arrItemNew.push(item);
        }
      }

    })
    return arrItemNew;
  } else {
    return arrItem.filter((item, index) => {
      return arrItem.indexOf(item) === index
    })
  }
};

MethodService.findListItemDuplicate = function (arrItem1, arrItem2) {
  return arrItem1.filter(function (val) {
    return arrItem2.indexOf(val) != -1;
  });
};

MethodService.markdownOption = {
  previewStyle: "vertical", //tab | vertical
  mode: "markdown",
  options: {
    minHeight: '200px',
    language: 'vi_VN',
    useCommandShortcut: true,
    useDefaultHTMLSanitizer: true,
    usageStatistics: false,
    hideModeSwitch: true,
    placeholder: 'Nhập nội dung...',
    toolbarItems: [
      'heading',
      'bold',
      'italic',
      'strike',
      'divider',
      'hr',
      'quote',
      'divider',
      'ul',
      'ol',
      'task',
      'indent',
      'outdent',
      'divider',
      'table',
      // 'image',
      'link',
      'divider',
      'code',
      // 'codeblock'
    ]
  }

};

MethodService.markdownModel = {
  dialogImage: false,
  dialogImageTab: "insert",
  dataFormImage: {
    title: null,
    link: null,
    file: null,
  },
  validFormImage: {
    title: [],
    link: [],
    file: [],
  },
};


MethodService.replaceURLImage = function (value) {
  return value.replace(
    /!\[([^\]]*)\]\(([^\]]*)([0-9a-fA-F]{24})\)/,
    "![$1]($3)"
  );
};

MethodService.unreplaceURLImage = function (value) {
  return value.replace(
    /!\[([^\]]*)\]\(([0-9a-fA-F]{24})\)/,
    `![$1](${ExtensionService.urlImg}$2)`
  );
};

MethodService.readExcel = function (dataFile) {
  return new Promise(function (resolve, reject) {
    readXlsxFile(dataFile).then((rows) => {
      resolve(rows);
    }, (error) => {
      toastr.error("Đọc file không thành công");
    });
  })

};

MethodService.checkSDT = function (value) {
  return RegExp(/^(0|\+?84|0084)\d{9}$/).test(value);
};

MethodService.filterTable = function (jsonFilter) {
  if (jsonFilter) {
    let dataSearch = JSON.parse(jsonFilter);
    let filters = "";
    $.each(dataSearch.value, (key, value) => {
      if (value) {
        if (dataSearch.operator[key]) {
          let operator = dataSearch.operator[key];
          let valueChange = value.replace("*", "\\\*"); //Sửa lỗi tìm dấu *
          if (operator == "<+1day") {
            operator = "<";
            valueChange = MethodService.addDate(value, "days", 1);
          }
          filters += key + operator + valueChange + "&";
        } else {
          filters += key + "=" + value + "&";
        }
      }
    });
    return filters.slice(0, -1);
  } else {
    return "";
  }

};

MethodService.sortObjectByKeyDate = function (obj, type) {
  var unordered = obj;
  var ordered = {};

  let keys = Object.keys(unordered);

  if (type == "asc") {
    keys.sort(function (a, b) {
      return new Date(a) - new Date(b);
    }).forEach(function (key) {
      ordered[key] = unordered[key];
    });
  }
  if (type == "desc") {
    keys.sort(function (a, b) {
      return new Date(b) - new Date(a);
    }).forEach(function (key) {
      ordered[key] = unordered[key];
    });
  }


  return ordered;
};
MethodService.formatSplit = function () {
  return 'xxx@xxx';
};

MethodService.hasChildObject = function (obj) {
  var flag = false;
  if (MethodService.isObject(obj)) {
    for (var propName in obj) {
      if (obj.hasOwnProperty(propName)) {
        flag = true;
      }
    }
  }
  return flag;

};
MethodService.decode = function (value) {
  console.log(value);
  // return window.atob(value);
  return Base64.decode(value);
};
MethodService.encode = function (value) {
  // return window.btoa(value);
  return Base64.encode(value);
};
MethodService.getHostApi = function () {
  return window.MyConfig.hostApi;
};
MethodService.isEmptyObject = function (obj) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
};
MethodService.isEmptyArray= function(value) {
  return Array.isArray(value) && !value.length
};

export default MethodService;
