<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-body">
        <h5 class="uppercase">Chào mừng đến với trang quản trị</h5>
        <!-- <b-row>
          <b-col sm="4" md="3" lg="3">
            <div class="brand-card">
              <div class="brand-card-header bg-primary">
                <i class="icon-home"></i>
              </div>
              <div class="brand-card-body">
                <div>
                  <div class="text-value">8</div>
                  <div class="text-uppercase text-muted small">Trang trại</div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="4" md="3" lg="3">
            <div class="brand-card">
              <div class="brand-card-header bg-danger">
                <i class="icon-people"></i>
              </div>
              <div class="brand-card-body">
                <div>
                  <div class="text-value">6</div>
                  <div class="text-uppercase text-muted small">Chủ trang trại</div>
                </div>
                <div>
                  <div class="text-value">28</div>
                  <div class="text-uppercase text-muted small">Người dùng</div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="4" md="3" lg="3">
            <div class="brand-card">
              <div class="brand-card-header bg-success">
                <i class="icon-user-following"></i>
              </div>
              <div class="brand-card-body">
                <div>
                  <div class="text-value">1</div>
                  <div class="text-uppercase text-muted small">Người dùng đang online</div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="4" md="3" lg="3">
            <div class="brand-card">
              <div class="brand-card-header bg-warning">
                <i class="fas fa-fish"></i>
              </div>
              <div class="brand-card-body">
                <div>
                  <div class="text-value">4</div>
                  <div class="text-uppercase text-muted small">Ao cá</div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cards",
  methods: {},
  data: function() {
    return {
      show: true
    };
  }
};
</script>

<style scoped>
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.card-body {
  min-height: 86vh;
}
</style>
