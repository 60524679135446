<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "app"
};
</script>

<style lang="scss">
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.min.css";
/* Import Font Awesome Icons Set 4.7 */
$fa-font-path: "~font-awesome/fonts/";
@import "~font-awesome/scss/font-awesome.scss";
/* Import Simple Line Icons Set */
$simple-line-font-path: "~simple-line-icons/fonts/";
@import "~simple-line-icons/scss/simple-line-icons.scss";
/* Import Flag Icons Set */
@import "~flag-icon-css/css/flag-icon.min.css";
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";
// Import Main styles for this application
@import "assets/scss/style";

//add new

@import "../node_modules/toastr/build/toastr.css";
@import "../node_modules/element-ui/lib/theme-chalk/index.css";
@import "../node_modules/nprogress/nprogress.css";
@import "../src/assets/scss/ladda.min.css";

//vue-quill
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import "../node_modules/quill/dist/quill.bubble.css";

//@toast-ui/vue-editor
@import '../node_modules/tui-editor/dist/tui-editor.css';
@import '../node_modules/tui-editor/dist/tui-editor-contents.css';
@import '../node_modules/codemirror/lib/codemirror.css';
@import "../src/assets/fontawesome/css/all.css";

@font-face {
  font-family: "Manrope";
  src: url("./fonts/Manrope-Regular.ttf");
}
body {
  font-family: "Manrope", "Courier New", Courier, monospace;
  font-size: 16px;
}

</style>
