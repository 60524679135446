<template>
  <div class="animated fadeIn row mt-2">
    <b-col md="1"></b-col>
    <b-col md="10">      
    </b-col>
    <b-col md="1"></b-col>
  </div>
</template>

<script>
import ValidService from "../../service/ValidService";
import MethodService from "../../service/MethodService";
import ApiService from "../../service/ApiService";
import DataService from "../../service/DataService";
export default {
  name: "home",
  data: function() {
    return {
      mData: {
        
        },
    };
  },
  computed: {
    
  },
  methods: {
    
  },

  mounted() {
    
  }
};
</script>

