<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header">
        <span v-if="mData.viewMode == 'create'"
          >{{ aLang.c_create }} thông tin ứng dụng</span
        >
        <span v-if="mData.viewMode == 'update'"
          >{{ aLang.c_update }} thông tin ứng dụng</span
        >
        <span v-if="mData.viewMode == 'detail'"
          >{{ aLang.c_detail }} thông tin ứng dụng</span
        >
        <div class="card-header-actions"></div>
      </div>
      <div class="card-body">
        <el-form
          :model="mData.dataForm"
          :rules="mData.validForm"
          ref="myForm"
          label-width="100%"
          label-position="top"
        >
          <b-row
            v-if="
              (mData.viewMode == 'create') |
                (mData.viewMode == 'update') |
                (mData.viewMode == 'detail')
            "
          >
            <b-col md="3">
              <el-form-item label="Mã ứng dụng" prop="application.code">
                <el-input
                  v-model="mData.dataForm.application.code"
                  :disabled="mData.viewMode == 'detail'"
                  clearable
                ></el-input>
              </el-form-item>
            </b-col>
            <b-col md="3">
              <el-form-item
                label="Tên ứng dụng"
                prop="application.name"
                clearable
              >
                <el-input
                  v-model="mData.dataForm.application.name"
                  :disabled="mData.viewMode == 'detail'"
                ></el-input>
              </el-form-item>
            </b-col>
            <b-col md="3">
              <el-form-item
                label="Môi trường mặc định"
                prop="application.defaultEnv"
                clearable
                :disabled="mData.viewMode == 'detail'"
              >
                <el-select
                  v-model="mData.dataForm.application.defaultEnv"
                  placeholder="Chọn môi trường"
                  allow-create
                  filterable
                  clearable
                >
                  <el-option
                    :disabled="mData.viewMode == 'detail'"
                    v-for="item in mData.dataDefault.environments"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </b-col>
            <b-col md="3">
              <el-form-item
                label="Danh sách người dùng"
                prop="application.userIds"
              >
                <el-select
                  v-model="mData.dataForm.application.userIds"
                  placeholder="Chọn người dùng"
                  multiple
                  :disabled="mData.viewMode == 'detail'"
                >
                  <el-option
                    :disabled="mData.viewMode == 'detail'"
                    v-for="item in this.mData.dataDefault.listUserIds"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </b-col>
          </b-row>
          <b-row
            v-if="
              (mData.viewMode == 'create') |
                (mData.viewMode == 'update') |
                (mData.viewMode == 'detail')
            "
          >
            <b-col md="12">
              <el-form-item class="text-right mt-3">
                <el-button
                  type="info"
                  plain
                  @click="fn_createEnvironment"
                  v-if="
                    (mData.viewMode == 'create') | (mData.viewMode == 'update')
                  "
                  >{{ aLang.c_create }} Môi trường</el-button
                >
              </el-form-item>
            </b-col>
            <b-col md="12">
              <el-table
                :data="mData.dataForm.application.environments"
                border
                stripe
                class="text-left"
                style="width: 100%"
              >
                <el-table-column
                  align="center"
                  label="STT"
                  type="index"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  align="left"
                  prop="envType"
                  label="Môi trường"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  align="left"
                  prop="privateKey"
                  label="Private Key"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  align="left"
                  prop="callBackUrl"
                  label="Callback Url"
                >
                </el-table-column>
                <el-table-column
                  :label="aLang.c_action"
                  align="center"
                  width="150px"
                  v-if="
                    (mData.viewMode == 'create') | (mData.viewMode == 'update')
                  "
                >
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="fn_handleEnvironments('delete', scope.$index)"
                      v-b-popover.hover.left="aLang.c_detail"
                    >
                      <i class="fa-light fa-trash-can-xmark"></i>
                    </el-button>
                    <el-button
                      size="mini"
                      @click="fn_handleEnvironments('update', scope.$index)"
                      v-b-popover.hover.left="aLang.c_update"
                    >
                      <i class="fa-light fa-pen"></i>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </b-col>
          </b-row>
          <el-form-item class="text-center mt-3">
            <el-button
              type="info"
              plain
              @click="fn_submitForm()"
              v-ladda
              v-if="mData.viewMode == 'create'"
              >{{ aLang.c_create }}</el-button
            >

            <el-button
              type="info"
              plain
              @click="fn_submitForm()"
              v-ladda
              v-if="mData.viewMode == 'update'"
              >{{ aLang.c_update }}</el-button
            >
            <el-button @click="fn_go()" type="info" plain>{{
              aLang.c_back
            }}</el-button>
          </el-form-item>
        </el-form>
        <el-form :model="mData.dataFormOther" :rules="mData.validFormOther">
          <el-dialog
            title="Environments"
            :visible.sync="mData.dataDefault.dialogVisible"
            width="60%"
            :close-on-click-modal="true"
          >
            <b-col md="12">
              <b-row>
                <b-col md="4">
                  <el-form-item
                    label="Danh sách môi trường"
                    prop="environment.envType"
                  >
                    <el-select
                      v-model="mData.dataFormOther.environment.envType"
                      placeholder="Chọn môi trường"
                      allow-create
                      filterable
                      clearable
                    >
                      <el-option
                        v-for="item in mData.dataDefault.environments"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </b-col>
                <b-col md="4">
                  <el-form-item
                    label="Private Key"
                    prop="environment.privateKey"
                    clearable
                  >
                    <el-input
                      v-model="mData.dataFormOther.environment.privateKey"
                      :disabled="mData.viewMode == 'detail'"
                      clearable
                    ></el-input>
                  </el-form-item>
                </b-col>
                <b-col md="4">
                  <el-form-item
                    label="Callback URL"
                    prop="environment.callBackUrl"
                  >
                    <el-input
                      v-model="mData.dataFormOther.environment.callBackUrl"
                      clearable
                    ></el-input>
                  </el-form-item>
                </b-col>
              </b-row>
            </b-col>
            <span slot="footer" class="dialog-footer">
              <el-button @click="mData.dataDefault.dialogVisible = false"
                >Hủy</el-button
              >
              <el-button type="primary" @click="fn_addEnvironment"
                >Đồng ý</el-button
              >
            </span>
          </el-dialog>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageService from "../../../service/LanguageService";
import MushroomUserApi from "../../../moduleApi/MushroomUserApi";
import MethodService from "../../../service/MethodService";
import ApplicationApi from "../../../moduleApi/ApplicationApi";
import AccountModel from "./ApplicationModel";
import MapComponent from "../../../component/MapComponent";

export default {
  components: {
    MapComponent,
  },
  data() {
    return {
      aLang: LanguageService.lang,
      mData: {
        viewMode: "create",
        viewModeEnvironment: "create",
        moduleName: "Application",
        dataDefault: {
          environmentIndex: null,
          listUserIds: [],
          dialogVisible: false,
          environments: [
            { value: "product", label: "Production" },
            { value: "staging", label: "Staging" },
            { value: "dev", label: "Development" },
          ],
        },
        dataForm: MethodService.copyObject(AccountModel.dataForm),
        dataFormOther: MethodService.copyObject(AccountModel.dataFormOther),
        validForm: AccountModel.validForm,
        validFormOther: AccountModel.validFormOther,
      },
    };
  },
  methods: {
    fn_createEnvironment() {
      this.mData.viewModeEnvironment = "create";
      this.mData.dataDefault.dialogVisible = true;
      this.mData.dataFormOther = MethodService.copyObject(
        AccountModel.dataFormOther
      );
    },
    fn_handleEnvironments(type, index) {
      if (type == "update") {
        this.mData.dataFormOther.environment = MethodService.copyObject(
          this.mData.dataForm.application.environments[index]
        );
        this.mData.dataDefault.dialogVisible = true;
        this.mData.dataDefault.environmentIndex = index;
        this.mData.viewModeEnvironment = type;
      } else if (type == "delete") {
        this.mData.dataForm.application.environments =
          this.mData.dataForm.application.environments.slice(index, 1);
      }
    },
    fn_addEnvironment() {
      let isExists = false;
      this.mData.dataForm.application.environments.forEach((item, index) => {
        if (
          (this.mData.dataDefault.environmentIndex != null &&
            index != this.mData.dataDefault.environmentIndex &&
            item.envType == this.mData.dataFormOther.environment.envType) ||
          (this.mData.dataDefault.environmentIndex == null &&
            item.envType == this.mData.dataFormOther.environment.envType)
        ) {
          isExists = true;
        }
      });
      if (isExists) {
        toastr.error("Môi trường đã tồn tại");
        return;
      }
      if (this.mData.viewModeEnvironment == "create") {
        this.mData.dataForm.application.environments.push(
          this.mData.dataFormOther.environment
        );
      } else {
        this.mData.dataForm.application.environments[
          this.mData.dataDefault.environmentIndex
        ] = this.mData.dataFormOther.environment;
        this.mData.dataDefault.environmentIndex = null;
      }
      this.mData.dataDefault.dialogVisible = false;
    },
    fn_submitForm() {
      this.$refs["myForm"].validate(async (valid) => {
        if (valid) {
          let data = MethodService.copyObject(this.mData.dataForm);
          if (this.mData.viewMode == "create") {
            await ApplicationApi.create(data.application);
            this.fn_resetForm();
          }
          if (this.mData.viewMode == "update") {
            await ApplicationApi.update(data.application);
            this.fn_go();
          }
        } else {
          MethodService.stopLadda();
          return false;
        }
      });
    },
    fn_resetForm() {
      this.$refs["myForm"].resetFields();
      this.mData.dataForm.application.environments = MethodService.copyObject(
        AccountModel.dataForm.application.environments
      );
    },
    fn_go() {
      this.$router.go(-1);
    },
    async fn_initDataDefault() {
      if (this.mData.viewMode == "create") {
        let reqDataUsers = MushroomUserApi.list();
        let resDataUsers = await reqDataUsers;
        resDataUsers.result.forEach((item) => {
          let data = {};
          data.value = item.id;
          data.label = item.account;
          this.mData.dataDefault.listUserIds.push(data);
        });
      }
    },
    async fn_findApplicationById(id) {
      let reqApp = ApplicationApi.findById(id);
      let resApp = await reqApp;
      this.mData.dataForm.application = resApp.result;
    },
  },
  created: function () {
    this.fn_initDataDefault();
    if (
      this.$router.currentRoute.name ==
      "Admin-" + this.mData.moduleName + "-update"
    ) {
      this.mData.viewMode = "update";
    }
    if (
      this.$router.currentRoute.name ==
      "Admin-" + this.mData.moduleName + "-detail"
    ) {
      this.mData.viewMode = "detail";
    }
    if (this.$router.currentRoute.params.id) {
      let applicationId = this.$router.currentRoute.params.id;
      this.fn_findApplicationById(applicationId);
    }
  },
  mounted: function () {},
};
</script>
