// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise';
import 'core-js/es6/string';
import 'core-js/es7/array';
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import App from './App';
import router from './router';
import { store } from './store/store';

Vue.use(BootstrapVue);

// import library

window.jQuery = window.$ = require('jquery');
window.toastr = require('toastr');
toastr.options = {
  "closeButton": true,
  "debug": false,
  "newestOnTop": false,
  "progressBar": true,
  "positionClass": "toast-top-right",
  "preventDuplicates": true,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "6000",
  "extendedTimeOut": "1000"
};
window.Ladda = require('ladda');

import VueQuillEditor from 'vue-quill-editor';
Vue.use(VueQuillEditor, {
  placeholder: "Nhập nội dung"
});

import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)

import Element from 'element-ui';
import locale from 'element-ui/lib/locale';
import enLocale from 'element-ui/lib/locale/lang/en';
import viLocale from 'element-ui/lib/locale/lang/vi';
enLocale.el.datepicker.year = "";
viLocale.el.datepicker.year = "";

if (window.MyConfig.language == "vi") {
  locale.use(viLocale)
}
if (window.MyConfig.language == "en") {
  locale.use(enLocale)
}

Vue.use(Element, {
  size: 'small',
  zIndex: 3000,
});


// import Service
import mushroom from 'mushroomjs';
mushroom.$using(window.MyConfig.hostApi);

mushroom._on("beforeSend", function (arg) {
  arg.headers = arg.headers || {};
  arg.headers["X-Client"] = "Survey";
  arg.headers["X-Client-Platform"] = "Mushroom";
  arg.headers["X-Client-Version"] = "1.0";
});

// import directive
import ladda from './directive/ladda';
Vue.directive('ladda', ladda);

import filters from './directive/filters';
Vue.filter('capitalize', filters.capitalize);


/* eslint-disable no-new */
window.vm = new Vue({
  el: '#app',
  router,
  template: '<App/>',
  store,
  data: function () {
    return {}
  },
  components: {
    App
  },
  methods: {},
  created: function () { },

})
