/**
 * Định nghĩa ngôn ngữ
 * Cách 1: [từ khóa, tiếng việt, tiếng anh]
 */
let dict = [
  ['key', 'vi', 'en'],
  // ['vi', 'en'],

  //common_text
  ['c_home', 'Trang chủ', 'Home'],
  ['c_homeAdmin', 'Trang chủ quản trị', 'Home'],
  ['c_create', 'Thêm mới', 'Add New'],
  ['c_list', 'Danh sách', 'List'],
  ['c_update', 'Cập nhật', 'Update'],
  ['c_delete', 'Xóa', 'Delete'],
  ['c_deletes', 'Xóa nhiều', 'Delete'],
  ['c_detail', 'Chi tiết', 'Detail'],
  ['c_duplicate', 'Sao chép', 'Duplicate'],
  ['c_view', 'Xem', 'view'],
  ['c_close', 'Đóng', 'Close'],
  ['c_search', 'Tìm kiếm', 'Search'],
  ['c_filter', 'Lọc dữ liệu', 'Filter'],
  ['c_back', 'Quay lại', 'Back'],
  ['c_success', 'thành công', 'Success'],
  ['c_error', 'không thành công', 'Unsuccesful'],
  ['c_record', 'bản ghi', 'Record'],
  ['c_fromDate', 'Từ ngày', 'From Day'],
  ['c_toDate', 'Đến ngày', 'To Day'],
  ['c_order', 'Xắp xếp', 'Sort'],
  ['c_drag', 'Kéo thả', 'Drag and Drop'],
  ['c_select', 'Chọn', 'Selected'],
  ['c_agree', 'Đồng ý', 'Agree'],
  ['c_cancel', 'Huỷ', 'Cancel'],
  ['c_showHideSearch', 'Ẩn/hiện tìm kiếm', 'Hide/Show search'],
  ['c_toggle', 'Thu nhỏ/phóng to', ''],
  ['c_action', 'Thao tác', 'Action'],
  ['c_total', 'Tổng số', 'Total'],
  ['c_toPage', 'Đến trang', 'To page'],
  ['c_enter', 'Nhập', 'Enter'],
  ['c_select', 'Chọn', 'Select'],
  ['c_yes', 'Có', 'Yes'],
  ['c_no', 'Không', 'No'],
  ['c_info', 'Thông tin', 'Information'],

  //common_alert 
  ['c_addSuccess', 'Thêm mới thành công', 'Add new success'],
  ['c_addError', 'Thêm mới không thành công', 'More new failed'],
  ['c_addduplicateError', 'Mã đã tồn tại, không thể thêm mới', 'More new failed'],
  ['c_updateSuccess', 'Cập nhật thành công', 'Successful update'],
  ['c_updateError', 'Cập nhật không thành công', 'Update failed'],
  ['c_deleteSuccess', 'Xóa thành công', 'Successful deleted'],
  ['c_deleteError', 'Xóa không thành công', 'Deletion failed'],
  ['c_uploadError', 'Tải lên không thành công', 'Upload failed'],
  ['c_recordSuccess', 'bản ghi thành công', 'Successful record'],
  ['c_recordError', 'bản ghi không thành công', 'Record failed'],
  ['c_deleteConfirm', 'Bạn có chắc chắn muốn xóa bản ghi này không?', 'Are you sure to delete this record?'],
  ['c_notAllowAccess', 'Không có quyền truy cập trang này', 'No access to this page'],
  ['c_emptyRecord', 'Bạn chưa chọn bản ghi nào', 'You have not selected any record'],
  ['c_loginAgain', 'Bạn cần đăng nhập trước', 'You need to login first,Please!'],
  ['c_findError', 'Lỗi tải dữ liệu', ''],
  ['c_uploadConfirm', 'Xác nhận upload file?', 'Are you sure to upload file?'],

  //validation
  ['valid', 'Được phép', ''],
  ['valid_required', 'Vui lòng nhập giá trị', 'Licensed'],
  ['valid_email', 'Email không đúng định dạng', 'Email invalidate'],
  ['valid_phone', 'Số điện thoại không đúng định dạng', 'Phone number is incorrect!'],
  ['valid_link', 'Liên kết không đúng định dạng', 'The link is not properly formatted'],
  ['valid_imageLink', 'Liên kết ảnh không đúng định dạng', 'Image Link is not properly formatted'],
  ['valid_sign', 'Không được nhập ký tự có dấu', 'Do not enter accented characters'],
  ['valid_space', 'Không được nhập ký tự trắng', 'Do not enter white characters'],
  ['valid_script', 'Không được nhập ký tự đặc biệt', 'do not enter special characters'],
  ['valid_az09', 'Chỉ được nhập từ a đến z, từ 0 đến 9', 'Only enter a to z and 0 to 9'],
  ['valid_account', 'Chỉ được nhập từ a đến z, từ 0 đến 9 và dấu _ . -', 'Only enter a to z and 0 to 9, and _ . -'],
  ['valid_az09Dot', 'Chỉ được nhập a đến z ,từ 0 đến 9 và dấu chấm', 'Only enter a to z , A to Z and the dots'],
  ['valid_phone', 'Số điện thoại không đúng'],
  ['valid_textFirst', 'Ký tự đầu tiên phải là chữ', 'The first character must be text'],
  ['valid_floatNegative', 'Không được nhập số âm', 'Do not enter number negative'],
  ['valid_dateTime', 'Ngày giờ không đúng định dạng', 'The data and time are incorrect'],
  ['valid_date', 'Ngày không đúng định dạng', 'The Day is incorrect'],
  ['valid_year', 'Năm không đúng định dạng', 'The Year is incorrect'],
  ['valid_month', 'Tháng không đúng định dạng', 'The month is incorrect'],
  ['valid_number', 'Chỉ được nhập số từ 0 đến 9', 'Only enter numbers from 0 to 9'],
  ['valid_integer', 'Chỉ được nhập số nguyên', 'Enter integers only '],
  ['valid_float', 'Chỉ được nhập số nguyên hoặc số thập phân', 'Only enter whole numbers or decimal numbers'],
  ['valid_tuNgay', 'Từ ngày phải nhỏ hơn hoặc bằng đến ngày', 'The date must be less than or equal to the date'],
  ['valid_denNgay', 'Đến ngày phải lớn hơn hoặc bằng từ ngày', 'The date must be greater than or equal to the date'],
  ['valid_upload', 'Vui lòng chọn file', 'Please selete the file'],
  ['valid_file', 'file', 'file'],
  ['valid_character', 'ký tự', 'characters'],
  ['valid_max', 'Không được lớn hơn', 'Do not the bigger'],
  ['valid_min', 'Không được nhỏ hơn', 'Not smaller'],
  ['valid_length', 'Giá trị nhập phải là', 'The input value must be'],
  ['valid_requiredUpload', 'Vui lòng chọn', 'Please , select'],
  ['valid_password', 'Nhập tổi thiểu 6 ký tự', 'Minimum six characters'],
  ['valid_insert', 'Nhập từ', 'Insert'],
  ['valid_to', '', 'đến', 'to'],
  ['valid_insertThan', 'Nhập lớn hơn', 'Insert than'],
  ['valid_insertLess', 'Nhập nhỏ hơn', 'Insert less'],
  ['valid_character', 'ký tự', 'character'],
  ['valid_compareTime', 'TG bắt đầu phải nhỏ hơn TG kết thúc', ''],
  ['valid_pattern', 'Không đúng định dạng', ''],



  //login
  ['login', 'Đăng nhập', 'Login'],
  ['login_error', 'Tên đăng nhập hoặc mật khẩu không đúng', 'Username or password is incorrect'],
  ['login_account', 'Tên đăng nhập', 'Username'],
  ['login_password', 'Mật khẩu', 'Password'],
  ['login_remember', 'Ghi nhớ đăng nhập', 'Remember Login'],

  //logout
  ['logout', 'Đăng xuất', 'Logout'],
  ['logout_all', 'Có đăng xuất khỏi các thiết bị khác?', 'Do you logout of other devices?'],
  ['logout_success', 'Đăng xuất thành công', 'Logout successful'],
  ['logout_error', 'Đăng xuất không thành công', 'Logout failed'],

  //changepassword
  ['c_changePasswordSuccess', 'Đổi mật khẩu thành công', 'Change password success'],
  ['c_changePasswordError', 'Đổi mật khẩu không thành công', 'Change password failed'],
  ['c_changePasswordErrorOld', 'Mật khẩu cũ không đúng', 'Old password wrong'],
  ['c_changePasswordErrorReEnterNew', 'Mật khẩu nhập lại không khớp', 'Re-enter password not match'],
  ['c_changePasswordErrorOldNew', 'Mật khẩu mới không được trùng với mật khẩu cũ', 'The new password must not match the old password'],
  ['c_resetPassword', 'Đặt lại mật khẩu', 'Reset password'],
  ['c_resetPasswordSuccess', 'Đặt lại mật khẩu thành công', 'Reset password success'],
  ['c_resetPasswordError', 'Đặt lại mật khẩu không thành công', 'Reset password failed'],

  //header
  ['header_goClient', 'Đến trang người dùng', 'To page users'],
  ['header_goChangePassword', 'Đổi mật khẩu', 'Change password'],

  //404
  ['p404_error', 'Đường dẫn không đúng!', 'The path is not correct'],
  ['p404_backPage', 'Trang trước đó', 'Pervious page'],

  //nav

  ['nav_qlnguoidung', 'Quản lý người dùng', 'User management'],
  ['nav_dsnguoidung', 'Danh sách người dùng', 'User list'],

  //user
  ['User', 'Người dùng', 'User'],
  ['user', 'người dùng', 'User'],
  ['user_disableConfirm', 'Bạn chắc chắn khóa người dùng này không?', 'Are you sure this user is locked?'],
  ['user_disableSuccess', 'Khóa người dùng thành công', 'Lock user sucessful'],
  ['user_disableError', 'Khóa người dùng không thành công', 'Lock user failed'],
  ['user_openDisableConfirm', 'Bạn chắc chắn mở khóa người dùng này không?', 'Are you sure to unlock this user?'],
  ['user_openDisableSuccess', 'Mở khóa người dùng thành công', 'Unlock user successful'],
  ['user_openDisableError', 'Mở khóa người dùng không thành công', 'Unlock user failed'],
  ['user_nameAccount', 'Tên đăng nhập', 'Name account'],
  ['user_roles', 'Quyền', 'Roles'],
  ['user_lockAccount', 'Khóa tài khoản', 'Lock account'],
  ['user_unlockAccount', 'Mở khóa tài khoản', 'Unlock account'],
  ['user_lock', 'Khóa', 'Lock'],
  ['user_unlock', 'Không Khóa', 'Unlock'],
  ['user_locked', 'Đã Khóa', 'Locked'],
  ['user_hidden', 'Đã Ẩn', 'Hidden'],
  ['user_unhide', 'Hiện', 'Show'],
  ['user_pinned', 'Đã Ghim', 'Pinned'],
  ['user_unpin', 'Không ghim', 'Unpin'],
  ['user_reEnterPass', 'Nhập lại mật khẩu', 'Re-enter the password'],
  ['user_password', 'Mật khẩu', 'Enter Password'],
  ['user_newPassword', 'Mật khẩu mới', 'Enter New Password'],
  ['user_oldPassword', 'Mật khẩu cũ', 'Enter Old Password'],
  ['user_whitePassword', 'Mật khẩu (Để trống là giữ nguyên mật khẩu)', 'Password (Leave blank to keep the password)'],
  ['user_position', 'Chức vụ', 'Positon'],
  ['user_nameUser', 'Tên Người dùng', 'Name user'],
  ['user_phoneNumber', 'Số điện thoại', 'Phone Number'],

  //surveyInfo
  ['c_example', 'Ví dụ:', 'Example:'],
  ['c_description', 'Mô tả:', 'Description:'],
  ['c_surveyInfoTitle', 'Mô tả thông tin biểu mẫu', ''],



  ['', '', ''],
];

let LanguageService = {
  vi: {},
  en: {},
  lang: {}
};

dict.forEach(item => {
  LanguageService.vi[item[0]] = item[1];
  LanguageService.en[item[0]] = item[2];
});
if (window.MyConfig.language == "vi") {
  LanguageService.lang = LanguageService.vi;
}
if (window.MyConfig.language == "en") {
  LanguageService.lang = LanguageService.en;
}
export default LanguageService;
