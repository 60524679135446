<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header">
        <span>Biểu đồ báo cáo biểu mẫu {{ this.mData.nameReport }}</span>
        <div class="card-header-actions"></div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col md="2"></b-col>
          <b-col md="3">
            <el-select
              v-model="mData.dataSelectItem"
              @change="displayReport()"
              placeholder="Chọn trường hiện thị"
            >
              <el-option
                v-for="item in mData.surveyItem"
                :key="item.id"
                :label="item.title"
                :value="item.code"
              ></el-option>
            </el-select>
          </b-col>
        </b-row>
        <b-row style="margin-top: 40px">
          <b-col md="2"></b-col>
          <b-col md="8">
            <b-row>
              <b-col md="12">
                <div class="card">
                  <div class="card-header">
                    Thông tin trường {{ this.mData.titleChart }}
                  </div>
                  <div class="card-body">
                    <div class="list-group" v-if="mData.type === 'text'">
                      <template v-for="(item, index) in mData.surveyResultText">
                        <button
                          :key="index"
                          class="list-group-item list-group-item-action"
                          type="button"
                        >
                          {{ item.result.value }}
                        </button>
                      </template>
                    </div>
                    <div v-if="mData.type === 'option'">
                      <ChartPie
                        :configChartPie="mData.ChartData"
                        :config="mData.ChartConfig"
                        ref="ChartPiei"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import SurveyItemApi from "../../../moduleApi/SurveyItemApi";
import SurveyResultApi from "../../../moduleApi/SurveyResultApi";
import ChartPie from "../../../component/ChartPieComponent";
import SurveyApi from "../../../moduleApi/SurveyApi";

// import _ from "lodash";
export default {
  components: {
    ChartPie,
  },
  data() {
    return {
      activeNames: ["1"],
      mData: {
        ChartConfig: { title: "" },
        titleChart: "",
        nameReport: "",
        type: null,
        dataSelectItem: null,
        surveyItem: [],
        surveyResultOption: [],
        surveyResultText: [],
        surveyData: [], // is convert from surveyResult
        ChartData: [],
      },
    };
  },
  methods: {
    async GetSurveyItem(surveyId) {
      if (surveyId) {
        try {
          let params = {
            filters: `surveyId=${surveyId}`,
          };
          let res = await SurveyItemApi.list(params);
          return res;
        } catch (error) {
          console.log(error);
          return [];
        }
      } else {
        return [];
      }
    },
    async GetSurveyList(id) {
      if (id) {
        try {
          let res = await SurveyApi.findById(id);
          return res.result;
        } catch (error) {
          console.log(error);
          return { name: "" };
        }
      } else {
        return { name: "" };
      }
    },
    async getRPTSurveyResult(surveyId, maCol) {
      if ((surveyId, maCol)) {
        try {
          let params = { surveyId: surveyId, itemCode: maCol };
          let res = await SurveyResultApi.rptSurveyResult(params);
          return res;
        } catch (error) {
          console.log(error);
          return [];
        }
      } else {
        return [];
      }
    },

    async getRPTSurveyResultText(surveyId, maCol) {
      if ((surveyId, maCol)) {
        try {
          let params = { surveyId: surveyId, itemCode: maCol };
          let res = await SurveyResultApi.rptSurveyResultText(params);
          return res;
        } catch (error) {
          console.log(error);
          return [];
        }
      } else {
        return [];
      }
    },

    async createOptionItem() {
      try {
        let { result: resItem } = await this.GetSurveyItem(
          this.$router.currentRoute.params.id
        );

        resItem = resItem.filter(
          (item) => item.inputControl != "image" && item.inputControl != "label"
        );
        resItem = resItem.map((item) => {
          if (
            item.inputControl == "textbox" ||
            item.inputControl == "number" ||
            item.inputControl == "email"
          ) {
            item.code += "__kieuTextBox";
          }
          return item;
        });
        this.mData.surveyItem = resItem;
        this.mData.dataSelectItem = resItem[0].code;
        // take name biểu mẫu báo cáo
        let { name: info } = await this.GetSurveyList(
          this.$router.currentRoute.params.id
        );
        this.mData.nameReport = info;
        this.displayReport();
      } catch (e) {
        console.log(e);
      }
    },

    IsRegex(operator, value) {
      const regex = new RegExp(operator);
      return regex.test(value);
    },
    convertDataChart(data) {
      if (data && data.length > 0) {
        let dataPoint = [];
        let total = data.reduce((x, y) => {
          return Number(x) + Number(y.total);
        }, 0);
        data.forEach((element) => {
          if (element.id) {
            dataPoint.push({
              name: element.id,
              y: Number(
                (Number(Number(element.total) / Number(total)) * 100).toFixed(2)
              ),
            });
          }
        });
        return dataPoint;
      } else return [];
    },
    async convertNameCodeCol(code_col) {
      try {
        let { result: resItem } = await this.GetSurveyItem(
          this.$router.currentRoute.params.id
        );
        if (resItem && resItem.length > 0) {
          let res = resItem.find((x) => x.code === code_col);
          if (res && res.title) {
            return res.title;
          }
        }
      } catch (error) {
        console.log(error);
        return "";
      }
    },
    async displayReport() {
      this.mData.surveyResultOption = [];
      this.mData.surveyResultText = [];
      let maCol = this.mData.dataSelectItem;
      let surveyId = this.$router.currentRoute.params.id;
      if (maCol && surveyId) {
        if (this.IsRegex(/\w+__kieuTextBox$/gm, maCol)) {
          this.mData.type = "text";
          maCol = maCol.replace(/__kieuTextBox$/gi, "");
          let dataText = await this.getRPTSurveyResultText(surveyId, maCol);
          this.mData.surveyResultText = dataText.result;
        } else {
          this.mData.type = "option";
          let dataOption = await this.getRPTSurveyResult(surveyId, maCol);
          this.mData.ChartData = await this.convertDataChart(dataOption.result);
        }
        this.mData.titleChart = await this.convertNameCodeCol(maCol);
        this.mData.ChartConfig.title =
          this.mData.titleChart + " - " + this.mData.nameReport;
        if (this.mData.type == "option")
          this.$refs.ChartPiei.displayCharts(this.mData.ChartData);
      }
    },
    handleChange(val) {
      console.log(val);
    },
  },
  mounted() {
    this.createOptionItem();
  },
};
</script>
