// export const updateValue = (state, payload) => {
//     state.value = payload
//   }

let mutations = {};

mutations.fn_countPhanHoi = (state) => {
    state.phanHoi.refreshFnCountNew++;
};

export default mutations;

