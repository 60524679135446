import ValidService from "../../../service/ValidService";
import moment from "moment";

let model = {};

// dữ liệu form
model.dataForm = {
  survey: {
    code: null, //string, required, pattern: /^[\w.]+$/, minLength: 1 // Mã biểu mẫu
    name: null, //string, required, minLength: 1 // Tên biểu mẫu
    description: null, //string, optional // Mô tả về biểu mẫu
    creatorId: null, //IdRef, optional // Mã hệ thống tạo biểu mẫu
    isEnabled: true, //boolean, required // Cho biết chỉ số vẫn còn hiệu lực hay không
    expriedDate: null, //datetime, optional // Ngày hết hạn gửi biểu mẫu
    isDeleted: false, //boolean, required // Cờ đánh dấu xóa mềm
    deletedUserId: null, //IdRef, optional // Mã người xóa
    deletedTime: null, //datetime, optional // Thời điểm xóa,
    applicationId: null, ///
    //disabled: false, //boolean, required // Cho biết tài khoản có bị khóa hay không, nếu bị khóa, tài khoản sẽ không thể đăng nhập được
    //roles: ["Admin", "Owner"], //array of string, required // Danh sách quyền của người dùng.
  },
  surveyItemValueTypeKeyValue: null,
  surveyItem: {
    code: null,
    title: null,
    surveyId: null,
    inputControl: null,
    description: null,
    labelType: "singleline",
    textboxType: null,
    fileId: null,
    isMultipleValue: false,
    dataSource: {
      requestApi: {},
    },
    validation: {
      required: false,
      min: null,
      max: null,
      length: null,
      minLength: null,
      maxLength: null,
      pattern: null,
      patternMessage: null,
    },
    condition: null,
    map: {
      center: [],
    },
    defaultValue: null,
    order: 0,
  },
  prefillData: {
    surveyToken: "",
    result: [],
    statusCallBack: false,
  },
  hashCodeObject: {
    privateKey: "",
    prefillData: "",
    callbackUrl: "",
    appCode: "",
    env: "",
  },
  requestApiData: [
    {
      code: "surveyItemCode",
      label: "Điều kiện lọc phụ thuộc vào trường",
      value: [],
    },
    { code: "method", label: "Method", value: "GET" },
    { code: "headers", label: "Headers", value: [] },
    { code: "body", label: "Body", value: {} },
    {
      code: "mappingFields",
      label: "Map trường dữ liệu",
      value: { key: "key", value: "value", data: "data" },
    },
  ]
};

// validation form
model.validForm = {
  survey: {
    code: [ValidService.required, ValidService.checkLength(3)], //string, required, minLength: 6 //
    name: [ValidService.required, ValidService.checkLength(6)], //string, optional //
    description: [ValidService.required, ValidService.checkLength(6)], //string, optional //
    expirdDate: [ValidService.required],
    applicationId: [ValidService.required],
  },
  surveyItem: {
    code: [ValidService.required, ValidService.checkLength(3)],
    title: [ValidService.required],
    dataSource: {
      type: [ValidService.requiredChange],
      value: [ValidService.requiredChange],
      requestApi: {
        url: [ValidService.required],
        method: [ValidService.required],
        type: [ValidService.required],
      },
    },
    inputControl: [ValidService.required],
    textboxType: [ValidService.required],
  },
  itemObject: {
    texbox: [],
    hiddent: [],
    email: [],
    datetime: [],
    number: [],
    selectbox: [],
    checkbox: [],
    radio: [],
  },
  applicationFormDefault: {
    env: [ValidService.required],
    callBackUrl: [],
  },
  surveyItemValueTypeKeyValue: [ValidService.required],
};

// dữ liệu mẫu các form khác
model.dataFormOther = {};

// validation các form khác
model.validFormOther = {};

model.tableRules = {
  data: [],
  lengthMenu: [10, 20, 50, 100, 500, 1000, 10000, 100000],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 10,
  offset: 0,
  sort: "",
  defaultSort: ["survey", "descending"],
  filters: "",
  recordSelected: [],
  dataSearch: {
    value: {
      ten: "",
      nong_trai_id: "",
      dien_thoai: "",
    },
    operator: {
      ten: ":regex_i:",
      nong_trai_id: "",
      dien_thoai: ":regex:_i",
    },
  },
};

export default model;
