import ApiService from '../service/ApiService';

let moduleApi = {};
let moduleName = "survey_result";
moduleApi.list = (dataFilter) => {
  let api = {
    module: moduleName,
    type: "list",
    value: dataFilter
  };
  return ApiService(api);
};
moduleApi.rptSurveyResultText = (dataFilter) => {
  let api = {
    module: "survey_result",
    viewAction: "rptSurveyResultText",
    type: "views",
    value: dataFilter
  };
  return ApiService(api);
};
moduleApi.rptSurveyResult = (dataFilter) => {
  let api = {
    module: "survey_result",
    viewAction: "rptSurveyResult",
    type: "views",
    value: dataFilter
  };
  return ApiService(api);
};
export default moduleApi;
