<template>
  <div class="app flex-row align-items-center" id="login-page">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card
              no-body
              class="text-white p-0 d-md-down-none"
              style="width:44%; background:rgb(184, 218, 222);"
            >
              <b-card-body class="text-center" style="position: relative;">
                <div class="bg-left">
                  <img src="img/img-login.png" alt />
                </div>
              </b-card-body>
            </b-card>
            <b-card no-body class="p-4">
              <b-card-body>
                <h3 class="text-center">{{aLang.login}}</h3>
                <b-alert
                  variant="danger"
                  dismissible
                  :show="mData.dataDefault.showDismissibleAlert"
                  @dismissed="mData.dataDefault.showDismissibleAlert=false"
                >{{mData.dataDefault.error}}</b-alert>

                <el-form
                  :model="mData.dataForm"
                  :rules="mData.validForm"
                  ref="myForm"
                  label-width="100%"
                  label-position="top"
                  @submit.native.prevent
                >
                  <el-form-item :label="aLang.login_account" prop="account">
                    <el-input
                      prefix-icon="el-icon-user"
                      v-model.trim="mData.dataForm.account"
                      clearable
                    ></el-input>
                  </el-form-item>

                  <el-form-item :label="aLang.login_password" prop="password">
                    <el-input
                      prefix-icon="el-icon-unlock"
                      v-model.trim="mData.dataForm.password"
                      show-password
                    ></el-input>
                  </el-form-item>
                  
                  <el-form-item label prop="remember">
                    <el-checkbox v-model="mData.dataForm.remember">{{aLang.login_remember}}</el-checkbox>
                  </el-form-item>

                  <el-form-item class="text-center">
                    <el-button
                      type="primary"
                      @click="fn_submitForm()"
                      v-ladda
                      native-type="submit"
                    >{{aLang.login}}</el-button>
                  </el-form-item>
                </el-form>
                <b-col md="12" class="text-center">
                  <b-link to="/auth/forgotPassword">Quên mật khẩu?</b-link>
                </b-col>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AuthService from "../../service/AuthService";
import LanguageService from "../../service/LanguageService";
import ValidService from "../../service/ValidService";
import MethodService from "../../service/MethodService";
export default {
  data() {
    return {
      aLang: LanguageService.lang,
      mData: {
        dataDefault: {
          error: "",
          showDismissibleAlert: false
        },
        dataForm: {
          account: "",
          password: "",
          remember: true
        },
        validForm: {
          account: [ValidService.required],
          password: [ValidService.required]
        }
      }
    };
  },
  methods: {
    fn_submitForm() {
      this.$refs["myForm"].validate(valid => {
        if (valid) {
          AuthService.login(
            this.mData.dataForm.account,
            this.mData.dataForm.password,
            this.mData.dataForm.remember
          )
            .then(res => {
              this.$router.push({ name: "Admin-Home" });
            })
            .catch(error => {
              this.mData.dataDefault.showDismissibleAlert = true;
              this.mData.dataDefault.error = LanguageService.lang.login_error;
            });
        } else {
          MethodService.stopLadda();
          return false;
        }
      });
      // this.$router.push({ name: "Admin-Home" });
    },
    fn_resetForm() {
      this.$refs["myForm"].resetFields();
    }
  },
  mounted: function() {}
};
</script>
<style>
.bg-left {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.bg-left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
