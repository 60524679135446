var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated fadeIn",attrs:{"id":"Form-survey-information"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header text-center bold"},[_vm._v(" "+_vm._s(_vm.aLang.c_surveyInfoTitle)+" ")]),_c('div',{staticClass:"card-body text-center"},[_vm._v("Thông tin hữu ích cần lưu ý")])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('label',{staticClass:"width-100"},[_c('span',[_vm._v(" Host URL")])])]),_c('div',{staticClass:"card-body"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex"},[_c('el-input',{staticClass:"width-100 pl-2",attrs:{"id":"hostUrl","readonly":"","value":_vm.mData.dataDefault.hostUrl}}),_c('i',{staticClass:"far fa-copy size-icon-copy cursor-pointer pl-2",on:{"click":_vm.fn_copyHostUrl}})],1)])],1)],1)])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('label',{staticClass:"width-100 cursor-pointer mb-0",on:{"click":_vm.fn_changeCustomParamStatus}},[_c('span',[_vm._v(_vm._s(_vm.mData.dataDefault.customParam.mark + _vm.mData.dataDefault.customParam.name))])])]),(_vm.mData.dataDefault.isShowCustomParam)?_c('div',{staticClass:"card-body"},[_c('div',[_c('el-form',{ref:"myForm",staticClass:"w-100",attrs:{"model":_vm.mData.dataDefault.surveyFormDefault,"rules":_vm.mData.applicationFormDefault,"label-width":"50%","label-position":"top"}},[_c('div',{staticClass:"pl-2 mb-1"},[_c('label',{staticClass:"width-100 list-group-item list-group-item-info"},[_c('span',[_vm._v("PrefillData")])]),_c('div',{staticClass:"pl-3 pr-3 background-color-gray pt-2 w-100"},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('el-form-item',{attrs:{"label":"Mã cột"}},[_c('el-select',{attrs:{"placeholder":"Select"},model:{value:(
                                _vm.mData.dataDefault.surveyFormDefault.code
                              ),callback:function ($$v) {_vm.$set(_vm.mData.dataDefault.surveyFormDefault, "code", $$v)},expression:"\n                                mData.dataDefault.surveyFormDefault.code\n                              "}},_vm._l((_vm.mData.dataForm.surveyItems),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.code,"value":item.code}})}),1)],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('el-form-item',{attrs:{"label":"Giá trị"}},[_c('el-input',{attrs:{"type":"textarea","autosize":""},model:{value:(
                                _vm.mData.dataDefault.surveyFormDefault.value
                              ),callback:function ($$v) {_vm.$set(_vm.mData.dataDefault.surveyFormDefault, "value", $$v)},expression:"\n                                mData.dataDefault.surveyFormDefault.value\n                              "}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('el-form-item',{attrs:{"label":"Thêm"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.fn_addToPrefillDataAndTable}},[_vm._v("Xác nhận")])],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"pb-2",attrs:{"md":"12"}},[_c('el-table',{staticClass:"text-left w-100 justify-content-center",attrs:{"data":_vm.mData.dataForm.prefillDataResult,"border":"","stripe":""}},[_c('el-table-column',{attrs:{"align":"center","label":"STT","type":"index","width":"100"}}),_c('el-table-column',{attrs:{"align":"left","prop":"code","label":"Mã câu hỏi","width":"auto"}}),_c('el-table-column',{attrs:{"align":"left","prop":"value","label":"Dữ liệu","width":"auto"}}),_c('el-table-column',{attrs:{"label":_vm.aLang.c_action,"align":"center","width":"auto"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.left",value:(_vm.aLang.c_delete),expression:"aLang.c_delete",modifiers:{"hover":true,"left":true}}],attrs:{"size":"mini"},on:{"click":function($event){return _vm.fn_handlePrefillData(
                                      'delete',
                                      scope.$index
                                    )}}},[_c('i',{staticClass:"fa-light fa-trash-can-xmark"})]),_c('el-button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.left",value:(_vm.aLang.c_update),expression:"aLang.c_update",modifiers:{"hover":true,"left":true}}],attrs:{"size":"mini"},on:{"click":function($event){return _vm.fn_handlePrefillData(
                                      'update',
                                      scope.$index
                                    )}}},[_c('i',{staticClass:"fa-light fa-pen"})])]}}],null,false,3900489402)})],1)],1)],1)],1)])])],1),_c('div',{staticClass:"pl-2 mb-1"},[_c('label',{staticClass:"width-100 list-group-item list-group-item-info"},[_c('span',[_vm._v("Application")])]),_c('div',{staticClass:"pl-3 pr-3 background-color-gray pt-2 w-100"},[_c('el-form',{ref:"myFormApplication",staticClass:"w-100",attrs:{"model":_vm.mData.dataDefault.applicationFormDefault,"rules":_vm.mData.dataForm.applicationFormDefault,"label-width":"50%","label-position":"top"}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('el-form-item',{attrs:{"label":"appCode"}},[_c('el-input',{staticClass:"width-100 pl-2",attrs:{"readonly":"","value":_vm.mData.dataForm.application.code}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('el-form-item',{attrs:{"label":"env","prop":"env"}},[_c('el-select',{attrs:{"placeholder":"Select"},model:{value:(
                              _vm.mData.dataDefault.applicationFormDefault.env
                            ),callback:function ($$v) {_vm.$set(_vm.mData.dataDefault.applicationFormDefault, "env", $$v)},expression:"\n                              mData.dataDefault.applicationFormDefault.env\n                            "}},_vm._l((_vm.mData.dataForm.application
                                .environments),function(item){return _c('el-option',{key:item.envType,attrs:{"label":item.envType,"value":item.envType}})}),1)],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('el-form-item',{attrs:{"label":"callBackUrl","prop":"callBackUrl"}},[_c('el-input',{staticClass:"width-100 pl-2",model:{value:(
                              _vm.mData.dataDefault.applicationFormDefault
                                .callBackUrl
                            ),callback:function ($$v) {_vm.$set(_vm.mData.dataDefault.applicationFormDefault
                                , "callBackUrl", $$v)},expression:"\n                              mData.dataDefault.applicationFormDefault\n                                .callBackUrl\n                            "}})],1)],1)],1)],1)],1)]),_c('div',{staticClass:"pl-2 mb-1"},[_c('label',{staticClass:"width-100 list-group-item list-group-item-info"},[_c('span',[_vm._v("ViewMode")])]),_c('div',{staticClass:"pl-3 pr-3 background-color-gray pt-2 w-100"},[_c('el-form',{ref:"myForm",staticClass:"w-100",attrs:{"model":_vm.mData.dataDefault.viewModeFormDefault,"rules":_vm.mData.validFormRender,"label-width":"50%","label-position":"top"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('el-form-item',{attrs:{"label":"viewMode"}},[_c('el-select',{attrs:{"placeholder":"Select"},model:{value:(
                              _vm.mData.dataDefault.viewModeFormDefault.viewMode
                            ),callback:function ($$v) {_vm.$set(_vm.mData.dataDefault.viewModeFormDefault, "viewMode", $$v)},expression:"\n                              mData.dataDefault.viewModeFormDefault.viewMode\n                            "}},[_c('el-option',{key:"create",attrs:{"label":"Thêm","value":"create"}}),_c('el-option',{key:"update",attrs:{"label":"Sửa","value":"update"}}),_c('el-option',{key:"view",attrs:{"label":"Xem","value":"view"}})],1)],1)],1)],1)],1)],1)]),_c('div',{staticClass:"pl-2 mb-1 text-center"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.fn_generateHostUrl}},[_vm._v("Tạo Host Url")])],1)]):_vm._e()])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"8"}},[_c('div',{staticClass:"card mt-4"},[_c('header',{staticClass:"card-header"},[_c('label',{staticClass:"width-100 cursor-pointer mb-0",on:{"click":_vm.fn_changeParamStatus}},[_c('span',[_vm._v(_vm._s(_vm.mData.dataDefault.params.mark + _vm.mData.dataDefault.params.name))])])]),(_vm.mData.dataDefault.isShowParams)?_c('div',{staticClass:"card-body"},_vm._l((_vm.mData.dataDefault.params.elements),function(item,index){return _c('div',{staticClass:"pl-2 mb-1"},[_c('label',{staticClass:"width-100 cursor-pointer list-group-item list-group-item-info",on:{"click":function($event){return _vm.fn_handleChange(index)}}},[_c('span',[_vm._v(_vm._s(item.mark + item.name))])]),(item.isShowDescription)?_c('div',{staticClass:"pl-3 background-color-gray pt-2"},[_c('label',{staticClass:"w-100"},[_c('span',[_vm._v(_vm._s(_vm.aLang.c_description)+" "+_vm._s(item.description.titleDescription))])]),_c('label',{staticClass:"w-100"},[_c('span',[_vm._v(_vm._s(item.description.detailDescription.name))])]),_vm._l((item.description.detailDescription.data),function(item1){return _c('label',{staticClass:"w-100 pl-3"},[_c('span',[_vm._v(_vm._s(item1))])])})],2):_vm._e()])}),0):_vm._e()])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }