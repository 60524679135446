<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header">
        <span v-if="mData.viewMode == 'create'">{{aLang.c_create}} chủ nông trại</span>
        <span
          v-if="mData.viewMode == 'update' || mData.viewMode == 'changePass'"
        >{{aLang.c_update}} tài khoản</span>
        <span v-if="mData.viewMode == 'detail'">{{aLang.c_detail}} tài khoản</span>
        <div class="card-header-actions"></div>
      </div>
      <div class="card-body">
        <el-form
          :model="mData.dataForm"
          :rules="mData.validForm"
          ref="myForm"
          label-width="100%"
          label-position="top"
        >
          <b-row v-if="mData.viewMode == 'create' || mData.viewMode == 'update'  || mData.viewMode == 'detail'  || mData.viewMode == 'changePass'">
            <b-col md="4">
              <el-form-item label="Tên tài khoản" prop="account.account">
                <el-input
                  v-model="mData.dataForm.account.account"
                  :disabled="mData.viewMode == 'detail' || mData.viewMode == 'update'"
                  clearable
                ></el-input>
              </el-form-item>
            </b-col>
            <b-col md="4">
              <el-form-item label="Quyền người dùng" prop="account.roles" clearable>
                <el-select
                  v-model="mData.dataForm.account.roles"
                  :placeholder="aLang.c_select"
                  :disabled="mData.viewMode == 'update' || mData.viewMode == 'detail'"
                  clearable
                  filterable
                  multiple
                >
                  <el-option
                    v-for="item in mData.dataDefault.roles"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </b-col>
            <b-col md="2">
              <el-form-item label="Khóa tài khoản" prop="account.roles" clearable>
                <el-checkbox
                  v-model="mData.dataForm.account.disabled"
                  :disabled="mData.viewMode == 'detail' || mData.viewMode == 'update'"
                ></el-checkbox>
              </el-form-item>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <el-form-item label="Mật khẩu" prop="account.password" clearable v-if="mData.viewMode == 'create' || mData.viewMode=='changePass'">
                <el-input
                  v-model="mData.dataForm.account.password"
                  show-password
                  clearable
                ></el-input>
              </el-form-item>
            </b-col>
            <b-col md="4">
              <el-form-item label="Nhập lại mật khẩu" prop="account.password" clearable v-if="mData.viewMode=='create' || mData.viewMode=='changePass'">
                <el-input
                  v-model="mData.dataForm.account.repassword"
                  show-password
                  clearable
                ></el-input>
              </el-form-item>
            </b-col>
          </b-row>
          <el-form-item class="text-center mt-3">
            <el-button
              type="info"
              plain
              @click="fn_submitForm()"
              v-ladda
              v-if="mData.viewMode == 'create'"
            >{{aLang.c_create}}</el-button>

            <el-button
              type="info"
              plain
              @click="fn_submitForm()"
              v-ladda
              v-if="mData.viewMode == 'update' || mData.viewMode == 'changePass'"
            >{{aLang.c_update}}</el-button>

            <el-button @click="fn_go()" type="info" plain>{{aLang.c_back}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageService from "../../../service/LanguageService";
import MethodService from "../../../service/MethodService";
import MushroomUserApi from "../../../moduleApi/MushroomUserApi";
import UserModel from "./UserModel";
import MapComponent from "../../../component/MapComponent";

export default {
  components: {
    MapComponent
  },
  data() {
    return {
      aLang: LanguageService.lang,
      mData: {
        viewMode: "create",
        moduleName: "User",
        dataDefault: {
          listNongTrai: [],
          roles: ['Admin', 'User'],
        },
        dataForm: MethodService.copyObject(UserModel.dataForm),
        validForm: UserModel.validForm
      }
    };
  },
  methods: {
    fn_submitForm() {
      this.$refs["myForm"].validate(async valid => {
        if (valid) {
          let data = MethodService.copyObject(this.mData.dataForm);

          if (this.mData.viewMode == "create") {
            if (data.account.password != data.account.repassword) {
              toastr.warning("Mật khẩu và nhập lại mật khẩu không khớp");
              MethodService.stopLadda();
              return false;
            }
            await MushroomUserApi.create(data.account);
            this.fn_resetForm();
          }
          if (this.mData.viewMode == "changePass") {
            if (data.account.password != data.account.repassword) {
              toastr.warning("Mật khẩu và nhập lại mật khẩu không khớp");
              MethodService.stopLadda();
              return false;
            }
            await MushroomUserApi.update(data.account);
            this.fn_go();
          }
        } else {
          MethodService.stopLadda();
          return false;
        }
      });
    },
    fn_resetForm() {
      this.$refs["myForm"].resetFields();
    },
    fn_go() {
      this.$router.go(-1);
    },

    async fn_getDataById(id) {
      if (this.mData.viewMode == "changePass") {
        let res = await MushroomUserApi.findById(id);
        this.mData.dataForm.account = res.result;
      } else {
        let res = await MushroomUserApi.findById(id);
        this.mData.dataForm.account = res.result;
      }
    },

    //xử lý dữ liệu đầu ra
    async fn_changeData(resData) {
      if (resData.nong_trai_id) {
        let res = await MushroomUserApi.findById(resData.nong_trai_id);
        this.mData.dataDefault.listNongTrai.push(res.result);
      }
      return resData;
    },

    async fn_initDataDefault() {
      if (this.mData.viewMode == "create") {
        let reqNongTraiApi = NongTraiApi.list({
          limit: 1000,
          filters: `chu_so_huu_id:is_null:true`
        });
        let resNongTraiApi = await reqNongTraiApi;

        this.mData.dataDefault.listNongTrai = resNongTraiApi.result;
      }
    }
  },
  created: function() {
    // this.fn_initDataDefault();
    if (
      this.$router.currentRoute.name ==
      "Admin-" + this.mData.moduleName + "-update"
    ) {
      this.mData.viewMode = "update";
      if (this.$router.currentRoute.query.viewMode == "changePass") {
        this.mData.viewMode = "changePass";
      }
    }
    if (
      this.$router.currentRoute.name ==
      "Admin-" + this.mData.moduleName + "-detail"
    ) {
      this.mData.viewMode = "detail";
    }
    if (this.$router.currentRoute.params.id) {
      this.fn_getDataById(this.$router.currentRoute.params.id);
    }
    console.log("$router.currentRoute.name");
    console.log(this.$router.currentRoute.name);
    console.log(this.mData.viewMode);
  },
  mounted: function() {}
};
</script>
