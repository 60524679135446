//import mushroom, {defineAsyncResource} from "mushroomjs";
Object.defineProperty(exports, "__esModule", { value: true });
var mushroom_1 = require("mushroomjs");
var mushroom = mushroom_1.default;
var defineAsyncResource = mushroom_1.defineAsyncResource;

//export * from "mushroomjs";
for (var m in mushroom_1) exports[m] = mushroom_1[m];

//import * as AuthExtension from "mushroomjs-auth";
var AuthExtension = require("mushroomjs-auth");
//import * as FileExtension from "mushroomjs-file";
var FileExtension = require("mushroomjs-file");
//export const extensions = {
var extensions = {
  Auth: AuthExtension,
  File: FileExtension,
};
exports.extensions = extensions;

("use strict");

defineAsyncResource({
  name: "mushroom_user",
  actions: {
    findMany: { clientCache: true, includeTotal: true },
    findById: { clientCache: true },
    createOne: {},
    updatePartially: {},
    deleteOne: {},
  },
  views: {},
});
defineAsyncResource({
  name: "user",
  actions: {
    findMany: { clientCache: true, includeTotal: true },
    findById: { clientCache: true },
    createOne: {},
    updatePartially: {},
    deleteOne: {},
  },
  views: {},
});
defineAsyncResource({
  name: "application",
  actions: {
    findMany: { clientCache: true, includeTotal: true },
    findById: { clientCache: true },
    createOne: {},
    updatePartially: {},
    deleteOne: {},
  },
  views: {},
});
defineAsyncResource({
  name: "survey",
  actions: {
    findMany: { clientCache: true, includeTotal: true },
    findById: { clientCache: true },
    createOne: {},
    updatePartially: {},
    deleteOne: {},
    _raw_http_method_generateHashCode: {},
    _raw_http_method_validateHashCode: {},
  },
  views: {},
});
defineAsyncResource({
  name: "survey_item",
  actions: {
    findMany: { clientCache: true, includeTotal: true },
    findById: { clientCache: true },
    createOne: {},
    createMany: {},
    updatePartially: {},
    deleteOne: {},
  },
  views: {},
});
defineAsyncResource({
  name: "survey_result",
  actions: {
    findMany: { clientCache: true, includeTotal: true },
    findById: { clientCache: true },
    createOne: {},
    updatePartially: {},
  },
  views: { rptSurveyResult: {}, rptSurveyResultText: {} },
});
defineAsyncResource({
  name: "survey_callback",
  actions: { findMany: { clientCache: true }, findById: { clientCache: true } },
  views: {},
});
defineAsyncResource({
  name: "prefill",
  actions: { findById: { clientCache: true }, createOne: {} },
  views: {},
});
defineAsyncResource({
  name: "province",
  actions: { findMany: { clientCache: true } },
  views: {},
});
defineAsyncResource({
  name: "district",
  actions: { findMany: { clientCache: true } },
  views: {},
});
defineAsyncResource({
  name: "commune",
  actions: { findMany: { clientCache: true } },
  views: {},
});

mushroom.$using("https://survey-api.test2.siten.vn/api/survey/v1/");
AuthExtension.useAuth(AuthExtension.AuthLocalStorage, mushroom_1.default);
FileExtension.useFile(mushroom_1.default);
//export default mushroom;
exports.default = mushroom;
